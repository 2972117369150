import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Table, Input, Button, Spinner } from 'reactstrap';

import {
  getPriceRanges,
  updatePriceRange,
  resetCustomerPriceRange,
} from '../redux/actions/settings';
import withTranslation from '../hocs/withTranslation';
import { numberWithCommas } from '../utils/common';

import PageSpinner from '../components/PageSpinner';
import toast from '../utils/toast';

class PriceRanges extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      updating: false,
      hasCustom: false,
      priceRanges: [],
      errors: {},
    };
  }

  componentDidMount() {
    this.setState({ loading: true }, () => {
      this.props
        .getPriceRanges(this.props.customerId)
        .then(this.updatePriceRangesInState);
    });

    if (this.props.forwarded)
      this.props.forwarded.current = {
        update: this.updatePriceRanges
      }
  }

  updatePriceRangesInState = res => {
    this.setState({
      loading: false,
      updating: false,
      hasCustom: res.data.hasCustom,
      priceRanges: res.data.priceRanges,
      errors: {},
    });
  };

  priceRangeErrorHandling = err => {
    let errors = {};

    if (err.response) {
      if (err.response.data && Array.isArray(err.response.data)) {
        err.response.data.forEach(error => {
          errors[`${error.value}`] = this.props.t(error.dictionaryKey);
        });
      }
    }

    this.setState({
      loading: false,
      updating: false,
      errors,
    });
  };

  updatePriceRanges = _ => {
    this.setState({ updating: true });
    return this.props
      .updatePriceRange(this.state.priceRanges, this.props.customerId)
      .then(res => {
        this.updatePriceRangesInState(res);

        if (!this.props.hideSave)
          toast.success(this.props.t(330));
        else
          return true
      })
      .catch(err => {
        this.priceRangeErrorHandling(err)

        if (this.props.hideSave)
          return false
      });
  };

  render() {
    let { priceRanges, loading, hasCustom, updating, errors } = this.state;

    const renderPrices = (range, key) => {
      const onChange = e => {
        let newRanges = priceRanges.map(x => {
          if (x.rangeId === range.rangeId)
            x.price = e.target.value.replace(',', '.');
          return x;
        });

        this.setState({
          priceRanges: newRanges,
        });
      };

      let err;
      if (errors[range.rangeId]) {
        err = errors[range.rangeId];
      }

      let inputText;
      if (range.isCustom) {
        inputText = this.props.t(356);
      }

      return (
        <tr key={key}>
          <td>{numberWithCommas(range.rangeStart) + '<'}</td>
          <td>
            <div className='flex-justify-content-center align-items-center'>
              <div
                className='margin-right-10px'
                style={{ flex: '4', textAlign: 'right' }}
              >
                {inputText}
              </div>
              <Input
                value={range.price}
                onChange={onChange}
                bsSize='sm'
                type='number'
                step='any'
                style={{ textAlign: 'right', flex: '3' }}
              />
            </div>
            {err && (
              <div style={{ color: 'red', textAlign: 'right' }}>{err}</div>
            )}
          </td>
        </tr>
      );
    };

    const onReset = () => {
      this.props
        .resetCustomerPriceRange(this.props.customerId)
        .then(this.updatePriceRangesInState);
    };

    return (
      <div className='settings gateway_tables'>
        {loading && <PageSpinner />}

        {!loading && (
          <React.Fragment>
            <div
              className='d-flex flex-column'
              style={{ width: 'max-content' }}
            >
              <Table striped size='sm'>
                <thead>
                  <tr>
                    <th>{this.props.t(192)}</th>
                    <th>{this.props.t(235)}</th>
                  </tr>
                </thead>
                <tbody>{priceRanges.map(renderPrices)}</tbody>
              </Table>
              <div className='mb-4 d-flex justify-content-center'>
                {hasCustom && (
                  <Button color='orange' className='mr-4' onClick={onReset}>
                    {this.props.t(279)}
                  </Button>
                )}
                {!this.props.hideSave && (
                  <Button
                    color='orange'
                    className=''
                    onClick={this.updatePriceRanges}
                  >
                    {!updating && this.props.t(19)}
                    {updating && <Spinner />}
                  </Button>
                )}
              </div>
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

export default withTranslation(
  connect(null, {
    getPriceRanges,
    updatePriceRange,
    resetCustomerPriceRange,
  })(PriceRanges),
);

import translation from './translation';

export const hasNumber = new RegExp('[0-9]+');
export const hasLetter = new RegExp('[A-Za-z]+', 'i');
export const hasMin8Chars = new RegExp('.{8,}');

const passValidator = password => {
  if (!password) {
    return { valid: false, text: translation(85) };
  }

  const HN = hasNumber.test(password);
  const HL = hasLetter.test(password);
  const HM8C = hasMin8Chars.test(password);
  const valid = HN && HL && HM8C;

  if (!valid) {
    if (!HN) {
      return { valid: valid, text: translation(77) };
    }
    if (!HL) {
      return { valid: valid, text: translation(78) };
    }
    if (!HM8C) {
      return { valid: valid, text: translation(79) };
    }
  } else {
    return { valid: valid, text: null };
  }
};

export default passValidator;

export default `    // Get SmsGateway Api configuration
    var endpoint = "[GATEWAY]";
    var token = "[API_TOKEN]";

    var client = new RestClient(endpoint + "/api/Sms/SendMulti");
    var request = new RestRequest(Method.POST);

    request.AddHeader("Authorization", $"Basic {token}");

    string msg = "Lorem ipsum dolor sit amet, consectetur adipiscing elit...";

    List<string> recievers = new List<string()
    {
        "+4511111111",
        "+4522222222",
        "+4533333333",
    };

    var sms = new SmsRequestMulti()
    {
        Recipients = receivers,
        Message = msg,
        Sender = "" // Optional
    };

    request.AddJsonBody(sms);

    client.Execute(request);
`;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Button, Spinner } from 'reactstrap';
import { Link, Redirect } from 'react-router-dom';

// Utils
import withTranslation from '../hocs/withTranslation';
import { getQueryKeyValuePairs } from '../utils/common';

// Actions
import { resetPassword } from '../redux/actions/auth';

import TextInput from '../components/Custom/Forms/TextInput';
import Typography from '../components/Typography';
import Smspilot from '../components/Custom/Smspilot';

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      password: '',
      passwordConfirm: '',
      loading: false,

      errObj: {
        passwordError: '',
        passwordConfirmError: '',
        tokenError: '',
      },
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  onSubmit(e) {
    e.preventDefault();
    const { password, passwordConfirm } = this.state;

    if (!password || !passwordConfirm) {
      this.setState({ errObj: { otherError: this.props.t(33) } });
      return;
    }

    const query = getQueryKeyValuePairs(this.props.location.search);
    const resetObj = {
      resetToken: query.resetToken,
      password,
      passwordConfirm,
    };

    this.setState({ loading: true, errObj: {} }, () => {
      this.props
        .resetPassword(resetObj)
        .then(res => {
          this.setState({ loading: false }, () => {
            window.location.search = '';
          });
        })
        .catch(err => {
          if (Array.isArray(err.response.data)) {
            const errors = err.response.data;
            const errObj = errors.reduce((prev, e) => {
              const key = e.dictionaryKey;

              switch (key) {
                case 171:
                  prev.tokenError = this.props.t(key);
                  break;
                case 169:
                  prev.passwordConfirmError = Array.isArray(
                    prev.passwordConfirmError,
                  )
                    ? [...prev.passwordConfirmError, this.props.t(key)]
                    : [this.props.t(key)];
                  break;
                case 257:
                case 258:
                case 259:
                case 260:
                  prev.passwordError = Array.isArray(prev.passwordError)
                    ? [...prev.passwordError, this.props.t(key)]
                    : [this.props.t(key)];
                  break;
                default:
                  break;
              }

              return prev;
            }, {});

            this.setState({
              errObj,
              loading: false,
            });
          } else {
            this.setState({ loading: false });
          }
        });
    });
  }

  render() {
    const { errObj } = this.state;
    if (!this.props.location.search) {
      return <Redirect to='/login' />;
    }

    let newPassError = null;
    let confirmPassError = null;
    if (Array.isArray(errObj.passwordError)) {
      newPassError = (
        <ul>
          {errObj.passwordError.map((errItem, key) => (
            <li key={key}>{errItem}</li>
          ))}
        </ul>
      );
    }
    if (Array.isArray(errObj.passwordConfirmError)) {
      confirmPassError = (
        <ul>
          {errObj.passwordConfirmError.map((errItem, key) => (
            <li key={key}>{errItem}</li>
          ))}
        </ul>
      );
    }

    return (
      <div className='login_wrapper'>
        <Form onSubmit={this.onSubmit} className='col-7 max-width'>
          <Smspilot />

          <Typography type='h5' className='text-center mb-5'>
            {this.props.t(172)}
          </Typography>

          <TextInput
            for={this.props.t(168)}
            name='password'
            type='password'
            value={this.state.password}
            placeholder={this.props.t(168)}
            onChange={this.onChange}
            error={newPassError}
            errorText={newPassError}
            combinedStyle
            showHide
          />

          <TextInput
            for={this.props.t(165)}
            name='passwordConfirm'
            type='password'
            value={this.state.passwordConfirm}
            placeholder={this.props.t(165)}
            onChange={this.onChange}
            error={confirmPassError}
            errorText={confirmPassError}
            combinedStyle
          />

          {errObj.otherError && (
            <p style={{ color: 'red' }}>{this.props.t(errObj.otherError)}</p>
          )}

          {errObj.tokenError && (
            <p style={{ color: 'red' }}>{this.props.t(errObj.tokenError)}</p>
          )}

          <Button size='lg' block color='orange' type='submit'>
            {this.state.loading && <Spinner />}
            {!this.state.loading && this.props.t(167)}
          </Button>

          <small className='d-block text-center mt-4'>
            <Link to='/login'>
              <u>{this.props.t(8)}</u>
            </Link>
          </small>
        </Form>
        <div className='footer'>
          <div className='items'>
            <small className='link-item'>
              {`smspilot ${this.props.t(193).toLowerCase()} `}
              <a href='https://itpilot.dk'>itpilot.dk</a>
            </small>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(
  connect(null, {
    resetPassword,
  })(ResetPassword),
);

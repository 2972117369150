import React, { Component } from 'react';
import { Table } from 'reactstrap';
import withTranslation from '../../../hocs/withTranslation';
import Typography from '../../../components/Typography';

class MessageSplitting extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderGsmCompliant = () => {
    return (
      <div>
        <Typography type='h5'>{this.props.t(428)}</Typography>
        <Table striped className='message-table'>
          <thead>
            <tr>
              <th>{this.props.t(426)}</th>
              <th>{this.props.t(427)}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1 - 160</td>
              <td>1</td>
            </tr>
            <tr>
              <td>161 - 306</td>
              <td>2</td>
            </tr>
            <tr>
              <td>307 - 459</td>
              <td>3</td>
            </tr>
            <tr>
              <td>460 - 612</td>
              <td>4</td>
            </tr>
            <tr>
              <td>613 - 765</td>
              <td>5</td>
            </tr>
            <tr>
              <td>766 - 918</td>
              <td>6</td>
            </tr>
            <tr>
              <td>919 - 1071</td>
              <td>7</td>
            </tr>
            <tr>
              <td>1072 - 1224</td>
              <td>8</td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  };

  renderNonGsmCompliant = () => {
    return (
      <div>
        <Typography type='h5'>{this.props.t(429)}</Typography>
        <Table striped className='message-table'>
          <thead>
            <tr>
              <th>{this.props.t(426)}</th>
              <th>{this.props.t(427)}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>1 - 70</td>
              <td>1</td>
            </tr>
            <tr>
              <td>71 - 134</td>
              <td>2</td>
            </tr>
            <tr>
              <td>135 - 201</td>
              <td>3</td>
            </tr>
            <tr>
              <td>202 - 268</td>
              <td>4</td>
            </tr>
            <tr>
              <td>269 - 335</td>
              <td>5</td>
            </tr>
            <tr>
              <td>336 - 402</td>
              <td>6</td>
            </tr>
            <tr>
              <td>403 - 469 </td>
              <td>7</td>
            </tr>
            <tr>
              <td>470 - 536</td>
              <td>8</td>
            </tr>
          </tbody>
        </Table>
      </div>
    );
  };

  render() {
    return (
      <div>
        {this.renderGsmCompliant()}
        {this.renderNonGsmCompliant()}
      </div>
    );
  }
}

export default withTranslation(MessageSplitting);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import classnames from 'classnames';
import {
  Row,
  Col,
  Nav,
  TabContent,
  NavItem,
  NavLink,
  TabPane,
} from 'reactstrap';

// Actions
import {
  setEndpoint,
  resetDocumentation,
} from '../../redux/actions/documentation';
import { getCustomerToken } from '../../redux/actions/customers';

// Utils
import withTranslation from '../../hocs/withTranslation';

// Pages
import ApiPages from './pages';

// Components
import Page from '../../components/Page';
import CodeBlock from '../../components/Custom/CodeBlock';
import Typography from '../../components/Typography';
import PageSpinner from '../../components/PageSpinner';

class Documentation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      activeTab: '1',
      activeExampleTab: '1',
      pages: ApiPages,
      customerToken: '[API_TOKEN]',
    };
  }

  componentDidMount() {
    this.props
      .getCustomerToken()
      .then(res => {
        this.setState({
          loading: false,
          customerToken: res.data,
        });
      })
      .catch(err => {
        this.setState({ loading: false });
      });
  }

  componentWillUnmount() {
    this.props.resetDocumentation();
  }

  onChangeTab = (id, title) => {
    this.setState({ activeTab: id }, () => {
      this.props.setEndpoint(title);
    });
  };

  onChangeExampleTab = id => {
    this.setState({ activeExampleTab: id });
  };

  renderContent = () => {
    const { pages, activeTab, customerToken } = this.state;

    const renderTab = (page, index) => {
      return (
        <TabPane key={index} tabId={`${index + 1}`}>
          {<page.component customerToken={customerToken} />}
        </TabPane>
      );
    };

    return (
      <TabContent activeTab={activeTab}>
        {pages.map((page, index) => renderTab(page, index))}
      </TabContent>
    );
  };

  renderNavigation = () => {
    const { pages, activeTab } = this.state;

    const createNav = (page, index) => {
      return (
        <NavItem key={index}>
          <NavLink
            className={classnames({ active: activeTab === `${index + 1}` })}
            onClick={() => this.onChangeTab(`${index + 1}`, page.title)}
          >
            {page.title}
          </NavLink>
        </NavItem>
      );
    };

    return <Nav tabs>{pages.map((page, index) => createNav(page, index))}</Nav>;
  };

  renderCodeExamples = () => {
    const { activeExampleTab, customerToken } = this.state;
    const { examples, responses } = this.props.doc;

    var responseKeys = Object.keys(responses);
    if (examples.length === 0) return null;
    let showResponses = responseKeys.length > 0;

    const createLanguageNavs = (example, index) => {
      return (
        <NavItem key={index}>
          <NavLink
            className={classnames({
              active: activeExampleTab === `${index + 1}`,
            })}
            onClick={() => this.onChangeExampleTab(`${index + 1}`)}
          >
            {example.title}
          </NavLink>
        </NavItem>
      );
    };

    const createLanguageTabs = (example, index) => {
      return (
        <TabPane key={index} tabId={`${index + 1}`}>
          <CodeBlock
            lineNumbers
            language={example.language}
            code={example.code
              .replace('[API_TOKEN]', customerToken)
              .replace('[GATEWAY]', process.env.REACT_APP_GATEWAY)}
          />
          {showResponses && (
            <div>
              <hr />
              <Typography type='h5'>{this.props.t(370)}</Typography>
              <CodeBlock language={'json'} code={responses.success} />
              <Typography type='h5'>{this.props.t(371)}</Typography>
              <CodeBlock language={'json'} code={responses.failure} />
            </div>
          )}
          {example.component}
        </TabPane>
      );
    };

    return (
      <Col sm={6}>
        <Nav tabs>
          {examples.map((example, index) => createLanguageNavs(example, index))}
        </Nav>
        <TabContent activeTab={activeExampleTab}>
          {examples.map((example, index) => createLanguageTabs(example, index))}
        </TabContent>
      </Col>
    );
  };

  render() {
    const { loading } = this.state;
    return (
      <Page title={this.props.t(358)}>
        {loading && <PageSpinner />}
        {!loading && (
          <Row>
            <Col>
              {this.renderNavigation()}
              {this.renderContent()}
            </Col>
            {this.renderCodeExamples()}
          </Row>
        )}
      </Page>
    );
  }
}

function mapStateToProps({ auth, doc }) {
  return {
    auth,
    doc,
  };
}

export default withTranslation(
  connect(mapStateToProps, {
    setEndpoint,
    resetDocumentation,
    getCustomerToken,
  })(Documentation),
);

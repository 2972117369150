/**
 * Contains functions whose purpose is to emit/create a Toast popup
 * @module utils/toast
 */
import { toast } from 'react-toastify';

// Default settings
const settings = {
  position: 'bottom-right',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

/**
 * Displays a green success popup.
 * @param {String} text text value
 */
function success(text) {
  toast.success(text, settings);
}

/**
 * Displays a blue info popup.
 * @param {String} text text value
 */
function info(text) {
  toast.info(text, settings);
}

/**
 * Displays a yellow warning popup.
 * @param {String} text text value
 */
function warning(text) {
  toast.warning(text, settings);
}

/**
 * Displays a red error popup.
 * @param {String} text text value
 */
function error(text) {
  toast.error(text, settings);
}

export default {
  success,
  info,
  warning,
  error,
};

import React, { Component } from 'react';
import { Table } from 'reactstrap';

class RequestBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { title, headers, cols, children } = this.props;
    let spanColumns = cols ? cols : headers.length;

    return (
      <div>
        <Table striped className='request-table' size='sm'>
          <thead>
            <tr>
              <th colSpan={spanColumns} className='title'>
                {title}
              </th>
            </tr>
            <tr>
              {headers.map((header, index) => (
                <th key={index}>{header}</th>
              ))}
            </tr>
          </thead>
          {children}
        </Table>
      </div>
    );
  }
}

export default RequestBlock;

import AuthForm from '../components/AuthForm';
import React from 'react';
import { connect } from 'react-redux';
import { login, confirmRegistration } from '../redux/actions/auth';
import { osName } from 'react-device-detect';
import { Link } from 'react-router-dom';
import { getQueryKeyValuePairs } from '../utils/common';
import withTranslation from '../hocs/withTranslation';
import Smspilot from '../components/Custom/Smspilot';
import Typography from '../components/Typography';

class AuthPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      failedLogin: false,
      errorText: null,
      showSpinner: false,
    };
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      this.props.history.push('/');
    } else if (this.props.location.search) {
      // Login via token
      const query = getQueryKeyValuePairs(this.props.location.search);

      this.setState({ showSpinner: true }, () => {
        this.props
          .confirmRegistration({
            token: query.confirmToken,
            devicetype: osName,
          })
          .then(res => {
            this.setState({ showSpinner: false }, () => {
              window.location.search = '';
              // window.location.pathname = '/';
            });
          })
          .catch(err => {
            let errorText = this.props.t(1090);
            if (err.response.data) {
              if (err.response.data.dictionaryKey) {
                errorText = this.props.t(err.response.data.dictionaryKey);
              }
            }
            this.setState({ failedLogin: true, showSpinner: false, errorText });
          });
      });
    }
  }

  handleLogoClick = () => {
    window.location.href = 'https://www.itpilot.dk/';
  };

  handleLogin = (username, password) => {
    this.setState({ showSpinner: true }, () => {
      this.props
        .login({
          username: username,
          password: password,
          devicetype: osName,
        })
        .then(res => {
          this.setState({ showSpinner: false }, () => {
            window.location.pathname = '/';
          });
        })
        .catch(err => {
          let errorText = this.props.t(163);
          if (err.response.data) {
            if (err.response.data.dictionaryKey) {
              errorText = this.props.t(err.response.data.dictionaryKey);
            }
          }
          this.setState({ failedLogin: true, showSpinner: false, errorText });
        });
    });
  };

  render() {
    return (
      <div className='login_wrapper'>
        <div className='col-7 max-width'>
          <Smspilot />

          <Typography type='h5' className='text-center mb-5'>
            {this.props.t(8)}
          </Typography>

          <AuthForm
            onLogoClick={this.handleLogoClick}
            login={this.handleLogin}
            loginFailed={this.state.failedLogin}
            errorText={this.state.errorText}
            showSpinner={this.state.showSpinner}
          />

          {<small className='d-block text-center mt-4'>
            {this.props.t(155)}
            &nbsp;
            <Link to='/register'>
              <u>{this.props.t(156)}</u>
            </Link>
          </small>}
        </div>
        <div className='footer'>
          <div className='items'>
            <small className='link-item'>
              {`smspilot ${this.props.t(193).toLowerCase()} `}
              <a href='https://itpilot.dk'>itpilot.dk</a>
            </small>
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
  };
}

export default withTranslation(
  connect(mapStateToProps, {
    login,
    confirmRegistration,
  })(AuthPage),
);

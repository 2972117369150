import React from 'react'
import { connect } from 'react-redux';

import { translation } from '../utils/translation';

const withTranslation = WrappedComponent => {
  const HOC = ({ translations, dispatch, ...props }) => {
    return <WrappedComponent {...props} t={translation(translations)} />
  };

  function mapStateToProps(state) {
    return {
      lang: state.common.user_lang,
      translations: state.common.translations,
      locale: state.common.locale
    };
  }

  return connect(mapStateToProps)(HOC)
}


export default withTranslation;

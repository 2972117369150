import React, { Component } from 'react';
import { Card, Table } from 'reactstrap';
import withTranslation from '../../../../hocs/withTranslation';
import Typography from '../../../../components/Typography';

class StatusCodes extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  renderApiStatusCodes = () => {
    const statuses = [
      {
        code: 201,
        resp: 'CREATED',
        desc: this.props.t(391),
      },
      {
        code: 400,
        resp: 'BAD_REQUEST',
        desc: this.props.t(392),
      },
      {
        code: 401,
        resp: 'UNAUTHORIZED',
        desc: this.props.t(393),
      },
      {
        code: 404,
        resp: 'NOT_FOUND',
        desc: this.props.t(394),
      },
      {
        code: 500,
        resp: 'INTERNAL_SERVER_ERROR',
        desc: this.props.t(395),
      },
    ];

    return (
      <Table striped className='status-table'>
        <thead>
          <tr>
            <th>{this.props.t(29)}</th>
            <th>{this.props.t(390)}</th>
            <th>{this.props.t(305)}</th>
          </tr>
        </thead>
        <tbody>
          {statuses.map((stat, index) => {
            return (
              <tr key={index}>
                <td>{stat.code}</td>
                <td>{stat.resp}</td>
                <td>{stat.desc}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  renderApplicationStatusCodes = () => {
    const statuses = [
      {
        code: 0,
        short: this.props.t(398),
        desc: this.props.t(399),
      },
      {
        code: 1000,
        short: this.props.t(400),
        desc: this.props.t(401),
      },
      {
        code: 1001,
        short: this.props.t(402),
        desc: this.props.t(403),
      },
      {
        code: 2000,
        short: this.props.t(404),
        desc: this.props.t(405),
      },
      {
        code: 3000,
        short: this.props.t(406),
        desc: this.props.t(407),
      },
      {
        code: 3001,
        short: this.props.t(408),
        desc: this.props.t(409),
      },
      {
        code: 3002,
        short: this.props.t(410),
        desc: this.props.t(411),
      },
      {
        code: 3003,
        short: this.props.t(412),
        desc: this.props.t(413),
      },
      {
        code: 3004,
        short: this.props.t(414),
        desc: this.props.t(415),
      },
      {
        code: 3005,
        short: this.props.t(416),
        desc: this.props.t(417),
      },
      {
        code: 3006,
        short: this.props.t(418),
        desc: this.props.t(419),
      },
      {
        code: 3007,
        short: this.props.t(420),
        desc: this.props.t(421),
      },
      {
        code: 4000,
        short: this.props.t(422),
        desc: this.props.t(423),
      },
    ];

    return (
      <Table striped className='status-table'>
        <thead>
          <tr>
            <th>{this.props.t(29)}</th>
            <th>{this.props.t(396)}</th>
            <th>{this.props.t(397)}</th>
          </tr>
        </thead>
        <tbody>
          {statuses.map((stat, index) => {
            return (
              <tr key={index}>
                <td>{stat.code}</td>
                <td>{stat.short}</td>
                <td>{stat.desc}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  };

  render() {
    return (
      <div>
        <Typography type='h5'>{this.props.t(424)}</Typography>
        <Card>{this.renderApiStatusCodes()}</Card>
        <hr className='border-0' />
        <Typography type='h5'>{this.props.t(425)}</Typography>
        <Card>{this.renderApplicationStatusCodes()}</Card>
      </div>
    );
  }
}

export default withTranslation(StatusCodes);

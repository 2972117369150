import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MdAccountBalanceWallet, MdCreditCard } from 'react-icons/md';
import ReactFlagsSelect from 'react-flags-select';

// redux
import { setLanguage } from '../../redux/actions/common';

import { getUserData } from '../../utils/userUtil';
import { languages } from '../../utils/translation';
import { getCurrentLang } from '../../utils/common';
import withTranslation from '../../hocs/withTranslation';

import 'react-flags-select/css/react-flags-select.css';
import { Link } from 'react-router-dom';

class UserDisplay extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,

      name: '',
      isLoginAs: false,
      loginAsName: '',
      pointBalance: 0,
    };
  }

  componentDidMount() {
    if (this.props.auth.isAuthenticated) {
      var user = getUserData(this.props.auth);
      this.setState({ ...user.userInfo, loading: false });
    }
  }

  renderDisplayName = () => {
    const { name, isLoginAs, loginAsName } = this.state;

    let nameClass = 'name_base';

    return (
      <div className={nameClass}>
        <div>{name}</div>
        {isLoginAs ? <small>{loginAsName}</small> : null}
      </div>
    );
  };

  renderLanguageDisplay = () => {
    let labels = {};

    languages.forEach(item => {
      labels[item.lang.toUpperCase()] = <small>{item.name}</small>;
    });

    const onLanguageSelect = countryCode => {
      this.props.setLanguage(countryCode.toLowerCase());
    };

    return (
      <div>
        <ReactFlagsSelect
          defaultCountry={getCurrentLang()}
          countries={['DK', 'GB']}
          customLabels={labels}
          onSelect={onLanguageSelect}
        />
      </div>
    );
  };

  renderAccount = () => {
    const user = getUserData(this.props.auth);
    if (user.userType !== 2) {
      return null;
    }

    return (
      <React.Fragment>
        <div className='headerInfo__accountItem'>
          <MdAccountBalanceWallet size={25} />
          <small>
            {parseFloat(this.state.pointBalance).toFixed(2)}{' '}
            {this.props.t(192).toLowerCase()}
          </small>
        </div>
        <Link to='/shop' className='headerInfo__accountItem'>
          <MdCreditCard size={25} />
          <small>{this.props.t(223)}</small>
        </Link>
      </React.Fragment>
    );
  };

  render() {
    let extraClass = 'user_area';

    return (
      <div className={extraClass}>
        {this.renderAccount()}
        {this.renderLanguageDisplay()}
        {this.renderDisplayName()}
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return {
    auth,
  };
}

export default withTranslation(
  connect(mapStateToProps, { setLanguage })(UserDisplay),
);

export default `<?php
function httpPost($url, $data) {
	$options = array(
		'http' => array(
			 'header' => array(
				"Content-type: application/json",
				"Authorization: Basic [API_TOKEN]" // Your API token
			 ),
        	'method' => 'POST',
        	'content' => json_encode($data)
    	)
    );
	$context  = stream_context_create($options);
	return file_get_contents($url, false, $context);
}

function send_sms_request($receiver, $message, $sender_name) {
	$sms_message = array (
		'Message' => $message,
		'RecipientPhone' => $receiver,
		'Sender' => $sender_name
	);
	
	return httpPost("[GATEWAY]/api/sms/send", $sms_message);
}
?>`;

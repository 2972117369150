export default `const axios = require('axios')
const API_BASE = '[GATEWAY]'
const API_KEY = '[API_TOKEN]' // Your API token


sendSmsMulti = (receivers, message, senderName) => {
    let data, config
    data = {
        Recipients: receivers,
        Message: message,
        sender: senderName
    }
    config = {
        headers: {
            Authorization: \`Basic \${API_KEY}\`
        }
    }
    axios.post(API_BASE + '/api/sms/sendMulti', data, config).then(res => {
        console.log(res.data)
    }).catch(error => {
        console.error(error)
    })
}`;

import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  FormGroup,
  Col,
  Label,
  FormText,
  Input,
  Card,
  Row,
  CardBody,
  Button,
  Spinner,
  CustomInput
} from 'reactstrap';

import { INVOICING_TYPES } from '../redux/constants';
import SettingsPointAddon from './SettingsPointAddon';
import PriceRanges from './SettingsPriceRange';

import PageSpinner from '../components/PageSpinner';
import Typography from '../components/Typography';
import Divider from '../components/Custom/Divider';

import { getCustomerInvoiceSettings, updateInvoiceSettings } from '../redux/actions/customers';
import { getUserData } from '../utils/userUtil';
import withTranslation from '../hocs/withTranslation';

class CustomerInvoiceSettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      updating: false,
      customer: null,
      hasError: false
    };

    this.pointAddonRef = React.createRef()
    this.priceRangeRef = React.createRef()
  }

  componentDidMount() {
    const user = getUserData(this.props.auth);

    let customerId = this.props.match.params.id;
    if (user.userType !== 1) {
      customerId = user.userInfo.id;
    }

    this.props.getCustomerInvoiceSettings(customerId).then(res => {
      this.setState({
        loading: false,
        customer: res.data,
      });
    });
  }

  onChangePoints = e => {
    let { name, value } = e.target;
    if (value !== '' && value) {
      value = parseInt(value);
    }

    this.setState({
      [name]: value,
    });
  };

  onSubmit = () => {
    const { customer } = this.state;

    this.setState({ updating: true, hasError: false }, async _ => {
      let pending = []

      if (this.pointAddonRef.current)
        pending = this.pointAddonRef.current.update()

      if (this.priceRangeRef.current)
        pending.push(this.priceRangeRef.current.update())

      // Wait for subrequests to finish
      await Promise.allSettled(pending)

      pending.push(this.props
        .updateInvoiceSettings(customer)
        .then(res => {
          this.setState({ customer: res.data });
          return true
        })
        .catch(_ => {
          return false
        }));

      let results = await Promise.allSettled(pending)
      let hasError = results.some(x => !x.value || x.status !== 'fulfilled')

      this.setState({ updating: false, hasError });

      if (!hasError)
        this.props.history.goBack()
    });
  };

  onChange = e => {
    let { name, value, checked, type } = e.target;

    if (type === 'checkbox') {
      value = checked;
    }

    if (type === 'number') {
      if (value !== '' && value) {
        value = parseInt(value);
      }
    }

    this.setState({
      customer: {
        ...this.state.customer,
        [name]: value,
      },
    });
  }

  generateDueDateOptions = (days = 365) => {
    let options = [];

    let val;
    for (let i = 0; i < days; i++) {
      val = i + 1;
      options.push(
        <option key={i} value={val}>
          {val} {this.props.t(292).toLowerCase()}{' '}
        </option>,
      );
    }

    return options;
  };

  renderSettingSwitch = (label, name, value, formText, bold = true) => {
    return (
      <FormGroup className='pt-4' row>
        <Col sm={8}>
          <Label for={name}>
            {bold && <strong>{label}</strong>}
            {!bold && <span>{label}</span>}
          </Label>
          {formText && <FormText>{formText}</FormText>}
        </Col>
        <Col sm={4}>
          <CustomInput
            id={name}
            name={name}
            checked={value}
            onChange={this.onChange}
            type='switch'
            className='float-right'
          />
        </Col>
      </FormGroup>
    );
  }

  render() {
    const { loading, customer, updating, hasError } = this.state;
    const { t } = this.props;

    if (loading) return <PageSpinner />;

    let isCustomer = getUserData(this.props.auth).userType !== 1;
    let isPrepaidType = parseInt(`${customer.invoicingType}`) === INVOICING_TYPES.Prepaid;

    return (
      <div className='customerEdit'>
        <div className='wrapper'>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <Typography type='h5'>
                    <strong>{t(518)}</strong>
                  </Typography>
                  {!isCustomer && (
                    <React.Fragment>
                      <FormGroup className='pt-4' row>
                        <Col sm={8}>
                          <Label for='invoicingType'>
                            <strong>{t(141)}</strong>
                          </Label>
                        </Col>
                        <Col sm={4}>
                          <Input
                            type='select'
                            id='invoicingType'
                            name='invoicingType'
                            value={customer.invoicingType}
                            onChange={this.onChange}
                          >
                            <option value={1}>{t(144)}</option>
                            <option value={2}>{t(145)}</option>
                          </Input>
                        </Col>
                      </FormGroup>

                      <SettingsPointAddon forwarded={this.pointAddonRef} customerId={customer.id} hideSave />
                      {`${customer.invoicingType}` === '2' && (
                        <PriceRanges customerId={customer.id} forwarded={this.priceRangeRef} hideSave />
                      )}
                      <Divider />
                    </React.Fragment>
                  )}

                  {!isCustomer && !isPrepaidType && (
                    <React.Fragment>
                      {this.renderSettingSwitch(
                        t(300),
                        'sendInvoiceMail',
                        this.state.customer.sendInvoiceMail,
                        t(301),
                        false,
                      )}
                      <Divider />
                    </React.Fragment>
                  )}

                  {!isPrepaidType && (
                    <React.Fragment>
                      <FormGroup className='pt-4' row>
                        <Col sm={8}>
                          <Label for='invoicingPeriodInterval'>{t(286)}</Label>
                        </Col>
                        <Col sm={4}>
                          <Input
                            type='select'
                            id='invoicingPeriodInterval'
                            name='invoicingPeriodInterval'
                            value={customer.invoicingPeriodInterval}
                            onChange={this.onChange}
                          >
                            <option value={1}>{t(287)}</option>
                            <option value={2}>{t(288)}</option>
                            <option value={3}>{t(289)}</option>
                          </Input>
                        </Col>
                      </FormGroup>
                      <Divider />
                    </React.Fragment>
                  )}

                  <FormGroup className='pt-4' row>
                    <Col sm={8}>
                      <Label for='currencyType'>{t(298)}</Label>
                    </Col>
                    <Col sm={4}>
                      <Input
                        type='select'
                        id='currencyType'
                        name='currencyType'
                        value={customer.currencyType}
                        onChange={this.onChange}
                      >
                        <option value={1}>DKK</option>
                        <option value={2}>EUR</option>
                      </Input>
                    </Col>
                  </FormGroup>

                  {!isCustomer && !isPrepaidType && (
                    <React.Fragment>
                      <Divider />
                      <FormGroup className='pt-4' row>
                        <Col sm={8}>
                          <Label for='invoiceDueDate'>{t(290)}</Label>
                          <FormText>{t(291)}</FormText>
                        </Col>
                        <Col sm={4}>
                          <Input
                            type='select'
                            id='invoiceDueDate'
                            name='invoiceDueDate'
                            value={customer.invoiceDueDate}
                            onChange={this.onChange}
                          >
                            {this.generateDueDateOptions()}
                          </Input>
                        </Col>
                      </FormGroup>
                    </React.Fragment>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Button
            color='orange'
            className='mt-4 mx-auto d-block'
            onClick={this.onSubmit}
          >
            {!updating && t(19)}
            {updating && <Spinner />}
          </Button>
          {hasError && <span style={{ color: 'red' }}>{t(326)}</span>}
        </div>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default withTranslation(
  connect(mapStateToProps, {
    getCustomerInvoiceSettings,
    updateInvoiceSettings
  })(CustomerInvoiceSettings),
);

import React, { Component } from 'react';
import withTranslation from '../../../hocs/withTranslation';
import RequestBlock from '../../../components/Custom/RequestBlock';

class MessageTypes extends Component {
  render() {
    return (
      <RequestBlock
        title={this.props.t(449)}
        headers={[this.props.t(450), this.props.t(438), this.props.t(441)]}
      >
        <tbody>
          <tr>
            <td>0</td>
            <td>{this.props.t(451)}</td>
            <td>{this.props.t(452)}</td>
          </tr>
          <tr>
            <td>1</td>
            <td>{this.props.t(453)}</td>
            <td>{this.props.t(454)}</td>
          </tr>
        </tbody>
      </RequestBlock>
    );
  }
}

export default withTranslation(MessageTypes);

import React, { Component } from 'react';
import withTranslation from '../../../hocs/withTranslation';
import Typography from '../../../components/Typography';
import RequestBlock from '../../../components/Custom/RequestBlock';

class SmsRequest extends Component {
  render() {
    let e164Format = this.props.t(444).split('[E.164]');
    e164Format = (
      <div>
        <Typography>
          {e164Format[0]}
          <a className='link' href={'https://en.wikipedia.org/wiki/E.164'}>
            E.164
          </a>
          {e164Format[1]}
        </Typography>
      </div>
    );

    return (
      <RequestBlock
        title={this.props.t(436)}
        headers={[
          this.props.t(437),
          this.props.t(438),
          this.props.t(439),
          this.props.t(440),
          this.props.t(441),
        ]}
      >
        <tbody>
          <tr>
            <td>RecipientPhone</td>
            <td>String</td>
            <td>{this.props.t(442)}</td>
            <td>{this.props.t(443)}</td>
            <td>{e164Format}</td>
          </tr>
          <tr>
            <td>Message</td>
            <td>String</td>
            <td>{this.props.t(442)}</td>
            <td>{this.props.t(443)}</td>
            <td>{this.props.t(445)}</td>
          </tr>
          <tr>
            <td>Sender</td>
            <td>String</td>
            <td>{this.props.t(46)}</td>
            <td>{this.props.t(45)}</td>
            <td>{this.props.t(446)}</td>
          </tr>
          <tr>
            <td>Type</td>
            <td>Integer (int32)</td>
            <td>{this.props.t(46)}</td>
            <td>{this.props.t(45)}</td>
            <td>{this.props.t(447)}</td>
          </tr>
        </tbody>
      </RequestBlock>
    );
  }
}

export default withTranslation(SmsRequest);

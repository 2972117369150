import React, { Component } from 'react';
import withTranslation from '../../../hocs/withTranslation';
import Typography from '../../../components/Typography';

class GsmCharacters extends Component {
  render() {
    return (
      <div>
        <Typography type='h5'>{this.props.t(35)}</Typography>
        <Typography>{this.props.t(316)}</Typography>
        <Typography>{this.props.t(317)}</Typography>
        <ul>
          <li>ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜÀÆØÅ</li>
          <li>abcdefghijklmnopqrstuvwxyzäöñüàæøå</li>
          <li>0123456789</li>
          <li>{`!"#¤%&'()*+,-./:;<=>?_`}</li>
          <li>ΦΓΛΩΠΨΣΘΞßÉ¡§¿Δ</li>
          <li>@£$¥</li>
          <li>èéùìòÇ</li>
          <li>\n (newline)</li>
          <li>\r (return)</li>
          <li>\x1b (escape)</li>
          <li> (whitespace)</li>
        </ul>
      </div>
    );
  }
}

export default withTranslation(GsmCharacters);

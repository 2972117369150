import React, { Component } from 'react';
import { connect } from 'react-redux';

import { Row, Col, Form, Button } from 'reactstrap';
import TextInput from './Forms/TextInput';
import PageSpinner from '../PageSpinner';

import withTranslation from '../../hocs/withTranslation';
import { sortStrings } from '../../utils/common';
import { getCountryCodes } from '../../redux/actions/common';
import ReCAPTCHA from 'react-google-recaptcha';

// Utils
import { RECAPTCHA_CLIENT_KEY } from '../../redux/constants';

class CustomerModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      customer: {
        name: '',
        cvr: '',
        contactName: '',
        contactMail: '',
        contactPhone: '',
        password: '',
        countryCodeId: 12 // Default '+45'
      },
      errObj: {},
      reCaptcha: null
    };
  }

  componentDidMount() {
    if (this.props.countryCodes.length === 0) {
      this.props.getCountryCodes();
    }
  }

  renderCountryCodeOption(code, key) {
    return (
      <option value={code.id} key={key}>
        {code.translation} ({code.code})
      </option>
    );
  }

  onChange = e => {
    this.setState({
      customer: {
        ...this.state.customer,
        [e.target.name]: e.target.value,
      },
    });
  };

  handleErrors = errors => {
    if (errors) {
      if (errors.response) {
        if (Array.isArray(errors.response.data)) {
          const { data } = errors.response;
          let errObj = data.reduce((prev, errItem) => {
            let key = errItem.dictionaryKey
            switch (key) {
              case 247:
                prev.companyNameError = this.props.t(key);
                break;
              case 244:
              case 517:
              case 520:
                prev.companyCvrError = this.props.t(key);
                break;
              case 281:
                prev.contactNameError = this.props.t(key);
                break;
              case 323:
              case 319:
              case 170:
                prev.contactEmailError = this.props.t(key);
                break;
              case 245:
              case 246:
                prev.contactPhoneError = this.props.t(key);
                break;
              case 257:
              case 258:
              case 259:
              case 260:
                prev.contactPasswordErrors = Array.isArray(prev.contactPasswordErrors) ? [...prev.contactPasswordErrors, this.props.t(key)] : [this.props.t(key)];
                break;
              default:
                prev.other = true
                break;
            }

            return prev;
          }, {});

          this.setState({ errObj });
        }
      }
    }
  };

  handleSubmit = () => {
    let { useReCaptcha, onSubmit } = this.props;
    let { customer, reCaptcha } = this.state;

    if (useReCaptcha && !reCaptcha)
      return;

    onSubmit(customer).catch(this.handleErrors);
  };

  render() {
    const { loading, isRegister, useReCaptcha, locale } = this.props;
    const { errObj } = this.state;
    const {
      name,
      contactName,
      contactMail,
      contactPhone,
      password,
      countryCodeId,
      cvr
    } = this.state.customer;

    const countryCodes = this.props.countryCodes
      .map(cc => {
        cc.translation = this.props.t(cc.key);
        return cc;
      })
      .sort((a, b) => sortStrings(a.translation, b.translation));

    let passwordError = null
    if (Array.isArray(errObj.contactPasswordErrors)) {
      passwordError = (
        <ul>
          {errObj.contactPasswordErrors.map((errItem, key) => <li key={key}>{errItem}</li>)}
        </ul>
      )
    }

    return (
      <Form>
        <TextInput
          for={this.props.t(213)}
          placeholder={this.props.t(213)}
          name='name'
          onChange={this.onChange}
          value={name}
          disabled={loading}
          error={errObj.companyNameError}
          errorText={errObj.companyNameError}
          combinedStyle={isRegister}
        />
        <TextInput
          for={this.props.t(217)}
          placeholder={this.props.t(217)}
          name='cvr'
          onChange={this.onChange}
          value={cvr}
          disabled={loading}
          error={errObj.companyCvrError}
          errorText={errObj.companyCvrError}
          combinedStyle={isRegister}
        />
        <TextInput
          for={this.props.t(93)}
          placeholder={this.props.t(93)}
          name='contactName'
          onChange={this.onChange}
          value={contactName}
          disabled={loading}
          error={errObj.contactNameError}
          errorText={errObj.contactNameError}
          combinedStyle={isRegister}
        />
        <TextInput
          for={this.props.t(28)}
          placeholder={this.props.t(28)}
          name='contactMail'
          onChange={this.onChange}
          value={contactMail}
          disabled={loading}
          error={errObj.contactEmailError}
          errorText={errObj.contactEmailError}
          combinedStyle={isRegister}
        />
        <Row>
          <Col>
            <TextInput
              for={this.props.t(183)}
              type='select'
              name='countryCodeId'
              value={countryCodeId}
              placeholder={this.props.t(183)}
              onChange={this.onChange}
              error={errObj.countryCodeIdError}
              errorText={errObj.countryCodeIdError}
              combinedStyle={isRegister}
            >
              {countryCodes.map(this.renderCountryCodeOption)}
            </TextInput>
          </Col>
          <Col>
            <TextInput
              for={this.props.t(27)}
              placeholder={this.props.t(27)}
              name='contactPhone'
              onChange={this.onChange}
              value={contactPhone}
              disabled={loading}
              error={errObj.contactPhoneError}
              errorText={errObj.contactPhoneError}
              combinedStyle={isRegister}
            />
          </Col>
        </Row>
        <TextInput
          for={this.props.t(15)}
          placeholder={this.props.t(15)}
          type='password'
          name='password'
          onChange={this.onChange}
          value={password}
          disabled={loading}
          error={passwordError}
          errorText={passwordError}
          combinedStyle={isRegister}
        />

        {useReCaptcha && (
          <div className='row justify-content-center my-4'>
            <ReCAPTCHA
              sitekey={RECAPTCHA_CLIENT_KEY}
              onChange={value =>
                this.setState({ reCaptcha: value })
              }
              hl={locale}
            />
          </div>
        )}

        <Button
          onClick={this.handleSubmit}
          size='lg'
          color='orange'
          className='border-0'
          block
        >
          {loading && <PageSpinner />}
          {!loading && this.props.t(152)}
        </Button>

        {errObj.other && (
          <p style={{ color: 'red' }}>{this.props.t(86)}</p>
        )}
      </Form>
    );
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    countryCodes: state.common.countryCodes
  };
}

export default withTranslation(connect(mapStateToProps, {
  getCountryCodes
})(CustomerModal));

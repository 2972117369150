import { SET_INVOICE_HISTORY, SET_INVOICE_HISTORY_PAGES } from '../constants';

const initialState = {
  invoices: [],
  pages: 1,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_INVOICE_HISTORY:
      return {
        ...state,
        invoices: action.payload,
      };
    case SET_INVOICE_HISTORY_PAGES:
      return {
        ...state,
        pages: action.payload,
      };
    default:
      return state;
  }
};

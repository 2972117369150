import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col } from 'reactstrap';

import withTranslation from '../../hocs/withTranslation';
import { sortStrings } from '../../utils/common';
import { getCountryCodes } from '../../redux/actions/common';

import TextInput from './Forms/TextInput';
import Typography from '../Typography';

class BillingForm extends Component {
  componentDidMount() {
    if (this.props.countryCodes.length === 0) {
      this.props.getCountryCodes();
    }
  }

  renderCountryCodeOption(code, key) {
    return (
      <option value={code.id} key={key}>
        {code.translation} ({code.code})
      </option>
    );
  }

  renderInfo = () => {
    const {
      billingInfo: {
        companyName,
        contactName,
        email,
        countryCodeId,
        phone,
        address,
        zipCode,
        city,
        cvrNumber,
        invoiceEmail,

        errObj,
      },
      onChange,
      isSettingsPage,
    } = this.props;

    const countryCodes = this.props.countryCodes
      .map(cc => {
        cc.translation = this.props.t(cc.key);
        return cc;
      })
      .sort((a, b) => sortStrings(a.translation, b.translation));

    return (
      <React.Fragment>
        <Row>
          {!isSettingsPage && (
            <Col>
              <Typography type={isSettingsPage ? 'h5' : 'h6'} className='mb-4'>
                {this.props.t(238)}
              </Typography>
            </Col>
          )}
        </Row>

        <Row form>
          <Col>
            <TextInput
              for={this.props.t(213)}
              name='companyName'
              value={companyName}
              placeholder={this.props.t(213)}
              onChange={onChange}
              error={errObj.companyNameError}
              errorText={errObj.companyNameError}
              combinedStyle={!isSettingsPage}
            />
          </Col>
          <Col>
            <TextInput
              for={this.props.t(140)}
              name='contactName'
              value={contactName}
              placeholder={this.props.t(140)}
              onChange={onChange}
              error={errObj.contactNameError}
              errorText={errObj.contactNameError}
              combinedStyle={!isSettingsPage}
            />
          </Col>
        </Row>

        <Row form>
          <Col md={6}>
            <TextInput
              for={this.props.t(16)}
              type='email'
              name='email'
              value={email}
              placeholder={this.props.t(16)}
              onChange={onChange}
              error={errObj.emailError}
              errorText={errObj.emailError}
              combinedStyle={!isSettingsPage}
            />
          </Col>
          <Col md={3}>
            <TextInput
              for={this.props.t(183)}
              type='select'
              name='countryCodeId'
              value={countryCodeId}
              placeholder={this.props.t(183)}
              onChange={onChange}
              error={errObj.countryCodeIdError}
              errorText={errObj.countryCodeIdError}
              combinedStyle={!isSettingsPage}
            >
              {countryCodes.map(this.renderCountryCodeOption)}
            </TextInput>
          </Col>
          <Col md={3}>
            <TextInput
              for={this.props.t(18)}
              name='phone'
              value={phone}
              placeholder={this.props.t(18)}
              onChange={onChange}
              error={errObj.phoneError}
              errorText={errObj.phoneError}
              combinedStyle={!isSettingsPage}
            />
          </Col>
        </Row>

        <Row form>
          <Col>
            <TextInput
              for={this.props.t(214)}
              name='address'
              value={address}
              placeholder={this.props.t(214)}
              onChange={onChange}
              error={errObj.addressError}
              errorText={errObj.addressError}
              combinedStyle={!isSettingsPage}
            />
          </Col>
          <Col>
            <TextInput
              for={this.props.t(215)}
              name='zipCode'
              value={zipCode}
              placeholder={this.props.t(215)}
              onChange={onChange}
              error={errObj.zipCodeError}
              errorText={errObj.zipCodeError}
              combinedStyle={!isSettingsPage}
            />
          </Col>
        </Row>

        <Row form>
          <Col>
            <TextInput
              for={this.props.t(216)}
              name='city'
              value={city}
              placeholder={this.props.t(216)}
              onChange={onChange}
              error={errObj.cityError}
              errorText={errObj.cityError}
              combinedStyle={!isSettingsPage}
            />
          </Col>
          <Col>
            <TextInput
              for={this.props.t(217)}
              name='cvrNumber'
              value={cvrNumber}
              placeholder={this.props.t(217)}
              onChange={onChange}
              error={errObj.cvrNumberError}
              errorText={errObj.cvrNumberError}
              combinedStyle={!isSettingsPage}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <TextInput
              for={this.props.t(275)}
              name='invoiceEmail'
              value={invoiceEmail}
              placeholder={this.props.t(275)}
              onChange={onChange}
              error={errObj.invoiceEmailError}
              errorText={errObj.invoiceEmailError}
              combinedStyle={!isSettingsPage}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  };

  render() {
    const { isSettingsPage } = this.props;
    return (
      <div>
        {!isSettingsPage && <Form>{this.renderInfo()}</Form>}
        {isSettingsPage && this.renderInfo()}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    countryCodes: state.common.countryCodes,
  };
}

export default withTranslation(
  connect(mapStateToProps, {
    getCountryCodes,
  })(BillingForm),
);

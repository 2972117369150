/**
 * @module actions/auth
 */
import axios from 'axios';
import {
  setAuthorizationToken,
  setDeviceToken,
} from './../../utils/setAuthorizationToken';
import {
  API_URL,
  SET_CURRENT_USER,
  SET_LOGIN_AS,
  LOGOUT_AS,
} from './../constants';
import { enumToUserLang } from '../reducers/common'

/**
 * @description Returns payload for dispatching SET_CURRENT_USER
 * @param {Object} user Object containing user data
 */
export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user,
  };
}

/**
 * @description Logs out user by removing localstorage data, removing Axios authorization and dispatching setCurrentUser with empty Object
 */
export function logout() {
  return dispatch => {
    window.localStorage.removeItem('user_lang');
    window.localStorage.removeItem('user_token');
    window.localStorage.removeItem('user_data');
    setAuthorizationToken(false);
    setDeviceToken(false);
    dispatch(setCurrentUser({}));
  };
}

/**
 * @description Logs in user by adding localstorage data, setting Axios authorization and dispatching setCurrentUser with user object
 * @param  {Object} data Object that will be posted to url
 */
export function login(data) {
  return dispatch => {
    clearStorage();
    return axios.post(API_URL + '/auth/login', data).then(res => {
      handleLogin(res, dispatch);

      return res;
    });
  };
}

function clearStorage() {
  const lang = window.localStorage.getItem('user_lang');
  window.localStorage.clear();
  if (lang || lang !== '') {
    window.localStorage.setItem('user_lang', lang);
  }
}

function handleLogin(res, dispatch) {
  const token = res.data.token;
  const data = JSON.stringify(res.data);
  window.localStorage.setItem('user_token', token);
  window.localStorage.setItem('user_data', data);
  window.localStorage.setItem('user_lang', enumToUserLang(res.data.userInfo.language));

  setAuthorizationToken(token);
  setDeviceToken(res.data.DeviceToken);
  dispatch(setCurrentUser(res.data));
}

/**
 * @description Changes the password for user
 */
export function changePassword(data) {
  let url = API_URL + '/auth/ChangePassword';
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Login as a client contact user
 * @param  {Object} data Object that will be posted to url
 */
export function loginAs(data) {
  return dispatch => {
    return axios.post(API_URL + '/auth/loginas', data).then(res => {
      const token = res.data.token;
      const _data = JSON.stringify(res.data);
      setAuthorizationToken(token);
      setDeviceToken(res.data.DeviceToken);
      window.localStorage.setItem('loggedInAs', _data);
      window.localStorage.setItem('user_lang', enumToUserLang(res.data.userInfo.language));
      dispatch({
        type: SET_LOGIN_AS,
        user: _data,
      });
      return res;
    });
  };
}

/**
 * @description Log out as a client contact user
 * @param  {Object} data Object that will be posted to url
 */
export function loginOutAs() {
  return dispatch => {
    window.localStorage.removeItem('loggedInAs');
    const token = window.localStorage.getItem('user_token');
    const data = JSON.parse(window.localStorage.getItem('user_data'));
    window.localStorage.setItem('user_lang', enumToUserLang(data.userInfo.language));

    setAuthorizationToken(token);
    setDeviceToken(data.DeviceToken);

    dispatch({
      type: LOGOUT_AS,
    });
  };
}

/**
 * @description Register a customer
 * @param {Object} data Object that will be posted to url
 */
export function register(data) {
  return dispatch => {
    return axios.post(API_URL + '/auth/register', data).then(res => {
      return res;
    });
  };
}

/**
 * Confirm a registered customer
 * @param {String} token Object that will be posted to url
 */
export function confirmRegistration(data) {
  return dispatch => {
    return axios.post(API_URL + '/auth/registerConfirm', data).then(res => {
      handleLogin(res, dispatch);
      return res;
    });
  };
}

export function forgotPassword(data) {
  return dispatch => {
    return axios.post(API_URL + '/auth/forgotPassword', data).then(res => {
      return res;
    });
  };
}

export function resetPassword(data) {
  return dispatch => {
    return axios.post(API_URL + '/auth/resetPassword', data).then(res => {
      return res;
    });
  };
}

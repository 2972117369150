import React, { Component } from 'react';
import { Card, CardBody, Badge } from 'reactstrap';
import { connect } from 'react-redux';
import withTranslation from '../../../hocs/withTranslation';

// Actions
import {
  setCodeExamples,
  setResponseBodies,
} from '../../../redux/actions/documentation';

// Utils
import Examples, { success, failure } from './CodeExamples';

// Components
import Typography from '../../../components/Typography';
import { SmsRequest, MessageTypes, GsmCharacters } from '../Shared';

class SendSms extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };
  }

  componentDidUpdate() {
    const { active } = this.state;
    if (this.props.doc.endpoint === 'SendSms' && !active) {
      this.setState({ active: true }, () => {
        this.props.setCodeExamples(Examples);
        this.props.setResponseBodies({ success, failure });
      });
    } else if (this.props.doc.endpoint !== 'SendSms' && active) {
      this.setState({ active: false });
    }
  }

  render() {
    return (
      <Card className='card-api-topic'>
        <CardBody>
          <Typography type='h4'>Send SMS</Typography>
          <div>
            <Badge pill color='info'>
              POST
            </Badge>
            <Badge color='disabled'>
              {process.env.REACT_APP_GATEWAY}/api/sms/send
            </Badge>
          </div>
          <hr className='border-0' />

          <Typography>{this.props.t(430)}</Typography>
          <Typography>{this.props.t(431)}</Typography>
          <Typography>{this.props.t(432)}</Typography>
          <Typography>{this.props.t(433)}</Typography>
          <Typography>{this.props.t(434)}</Typography>

          <SmsRequest />
          <MessageTypes />
          <GsmCharacters />
        </CardBody>
      </Card>
    );
  }
}

function mapStateToProps({ doc }) {
  return {
    doc,
  };
}

export default withTranslation(
  connect(mapStateToProps, { setCodeExamples, setResponseBodies })(SendSms),
);

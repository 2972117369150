import csharp from './csharp.js';
import nodejs from './nodejs.js';
import php from './php.js';

export default [
  {
    language: 'csharp',
    title: 'C#',
    code: csharp,
  },
  {
    language: 'javascript',
    title: 'Node.JS',
    code: nodejs,
  },
  {
    language: 'php',
    title: 'PHP',
    code: php,
  },
];

export const failure = `{
  "message": "One or more messages failed or could not be sent.",
  "status": 3007,
  "results": [
      {
          "status": 0,
          "guid": "4430972c369d4f8c94389703f6f7d327204714343",
          "message": "Success: Message added to the queue."
      },
      {
          "status": 3005,
          "guid": "8405c00e44b54a059a3d45bec80a2638104068339",
          "message": "Message too long: The length of the message exceeds the allowed number of characters."
      }
  ]
}`;

export const success = `{
  "message": "Created Multiple.",
  "status": 0,
  "results": [
      {
          "status": 0,
          "guid": "f115ee0d61a4465dbab5ce13745314811354445938",
          "message": "Lorem ipsum dolor sit amet, consectetur adipiscing elit..."
      },
      {
          "status": 0,
          "guid": "ed3edf930b2044e0aafdcd8d67699428481845944",
          "message": "Lorem ipsum dolor sit amet, consectetur adipiscing elit..."
      }
  ]
}`;

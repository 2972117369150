import React, { Component } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import {
  atomOneDark,
  docco,
} from 'react-syntax-highlighter/dist/esm/styles/hljs';

class CodeBlock extends Component {
  constructor(props) {
    super(props);
    this.state = {
      style: props.light ? docco : atomOneDark,
    };
  }

  render() {
    const { style } = this.state;
    let { language, code, lineNumbers } = this.props;

    if (language === 'nodejs') {
      language = 'javascript';
    }

    return (
      <SyntaxHighlighter
        showLineNumbers={lineNumbers}
        language={language}
        style={style}
      >
        {code}
      </SyntaxHighlighter>
    );
  }
}

export default CodeBlock;

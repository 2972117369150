import csharp from './csharp.js';
import nodejs from './nodejs.js';
import php from './php.js';

export default [
  {
    language: 'csharp',
    title: 'C#',
    code: csharp,
  },
  {
    language: 'javascript',
    title: 'Node.JS',
    code: nodejs,
  },
  {
    language: 'php',
    title: 'PHP',
    code: php,
  },
];

export const failure = `{
  "message": "Low points: You have reached the end of your message points.",
  "status": 2000,
  "guids": [
      "6f78652c91bf47058b69fa59b3db8eaa699130336"
  ]
}
`;

export const success = `{
  "message": "Success: Message added to the queue.",
  "status": 0,
  "guids": [
      "4e70095041b541cca55a80b672006ef11478057422"
  ]
}`;

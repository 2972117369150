import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  FormGroup,
  Col,
  Label,
  FormText,
  Input,
  Card,
  Row,
  CardBody,
  Button,
  Spinner,
} from 'reactstrap';
import { getNumberDecimal } from '../utils/common';
import { getUserData } from '../utils/userUtil';
import PageSpinner from '../components/PageSpinner';
import { getCustomer, updateCustomerPoints } from '../redux/actions/customers';
import withTranslation from '../hocs/withTranslation';

class CustomerPoints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      updating: false,
      customer: null,
      addPoints: 0,
    };
  }

  componentDidMount() {
    let customerId = this.props.match.params.id;

    this.props.getCustomer(customerId).then(res => {
      this.setState({
        loading: false,
        customer: this.getCustomerFields(res.data),
      });
    });
  }

  getCustomerFields(customer) {
    return {
      id: customer.id,
      name: customer.name,
      points: customer.points,
    };
  }

  onChangePoints = e => {
    let { name, value } = e.target;
    if (value !== '' && value) {
      value = parseInt(value);
    }

    this.setState({
      [name]: value,
    });
  };

  onSubmit = () => {
    const { customer } = this.state;

    const isCustomer = getUserData(this.props.auth).userType !== 1;
    if (!isCustomer) {
      this.setState({ updating: true }, () => {
        this.props
          .updateCustomerPoints({
            id: customer.id,
            points: this.state.addPoints,
          })
          .then(res => {
            this.setState(
              {
                customer: this.getCustomerFields(res.data),
                addPoints: 0,
                updating: false,
              },
              () => this.props.history.goBack(),
            );
          })
          .catch(err => {
            this.setState({ updating: false });
          });
      });
    }
  };

  render() {
    const { loading } = this.state;
    const { t } = this.props;

    if (loading) return <PageSpinner />;

    return (
      <div className='customerEdit'>
        <div className='wrapper'>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <FormGroup className='pt-4' row>
                    <Col sm={8}>
                      <Label for='addPoints'>
                        <strong>{t(192)}</strong> ({t(252)}:{' '}
                        {getNumberDecimal(this.state.customer.points)})
                      </Label>
                      <FormText>{t(251)}</FormText>
                    </Col>
                    <Col sm={4}>
                      <Input
                        type='number'
                        step='any'
                        id='addPoints'
                        name='addPoints'
                        value={this.state.addPoints}
                        onChange={this.onChangePoints}
                        style={{ textAlign: 'right' }}
                      />
                    </Col>
                  </FormGroup>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Button
            color='orange'
            className='mt-4 mx-auto d-block'
            onClick={this.onSubmit}
          >
            {!this.state.updating && this.props.t(19)}
            {this.state.updating && <Spinner />}
          </Button>
        </div>
      </div>
    );
  }
}

function mapStateToProps({ auth }) {
  return { auth };
}

export default withTranslation(
  connect(mapStateToProps, { getCustomer, updateCustomerPoints })(
    CustomerPoints,
  ),
);

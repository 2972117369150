import React from 'react';
import {
  // MdExitToApp,
  // MdInsertChart,
  MdShowChart,
  MdSend,
  MdSupervisorAccount,
  MdSettings,
  MdDashboard,
} from 'react-icons/md';
import { FaFileInvoice } from 'react-icons/fa';
import { AiFillApi } from 'react-icons/ai';
import { getUserData } from './userUtil';
import CustomerEditPage from '../pages/CustomerEditPage';
import Documentation from '../pages/Documentation';

export const isSuperAdmin = auth => {
  const user = getUserData(auth);

  /*if (user.userInfo) {
    if (user.userInfo.isLoginAs) {
      return user.userInfo.loginAsType === 1;
    }
  }*/

  return user.userType === 1;
};

// Page lazyloading (code-splitting --> quicker loading, efficiency)
const DashboardPage = React.lazy(() => import('../pages/DashboardPage'));
const CustomersPage = React.lazy(() => import('../pages/CustomerPage'));
const MessagesPage = React.lazy(() => import('../pages/Messages'));
const StatisticsPage = React.lazy(() => import('../pages/Statistics'));
const SettingsPage = React.lazy(() => import('../pages/Settings'));
const ShopPage = React.lazy(() => import('../pages/Shop'));
const OrderHistoryPage = React.lazy(() => import('../pages/OrderHistory'));

export const routes = (auth, redirectOnUnAuth = true) => {
  const navItems = [];
  let user_data;

  if (auth.isAuthenticated) {
    if (auth.loginAsUser) {
      user_data = JSON.parse(auth.loginAsUser);
    } else {
      user_data = JSON.parse(localStorage.getItem('user_data'));
    }
  } else if (redirectOnUnAuth) {
    window.location.pathname = '/login';
    //window.location.reload(); // <--- Page does not update on push, forcing reload
  } else {
    return navItems;
  }

  const isAdmin = user_data.userType === 1;

  if (!isAdmin) {
    navItems.push(
      {
        to: '/dashboard',
        name: 177,
        exact: true,
        Icon: MdDashboard,
        component: DashboardPage,
      },
      {
        to: '/shop',
        name: 'shop',
        exact: true,
        component: ShopPage,
        hidden: true,
      },
    );
  }

  if (isAdmin) {
    navItems.push({
      to: '/customers',
      name: 5,
      exact: true,
      Icon: MdSupervisorAccount,
      component: CustomersPage,
    });
    navItems.push({
      to: '/customers/:id',
      name: 5,
      exact: true,
      Icon: MdSupervisorAccount,
      component: CustomerEditPage,
      hidden: true,
    });
  }

  navItems.push(
    {
      to: '/messages',
      name: 35,
      exact: true,
      Icon: MdSend,
      component: MessagesPage,
    },
    {
      to: '/statistics',
      name: 37,
      exact: true,
      Icon: MdShowChart,
      component: StatisticsPage,
    },
    {
      to: '/invoices',
      name: 277,
      component: OrderHistoryPage,
      Icon: FaFileInvoice,
    },
    {
      to: '/documentation',
      name: 358,
      component: Documentation,
      Icon: AiFillApi,
    },
  );

  navItems.push({
    to: '/settings',
    name: 94,
    Icon: MdSettings,
    component: SettingsPage,
  });

  return navItems;
};

/**
 * @module actions/settings
 */
import axios from 'axios';
import {
  API_URL,
  SET_GATEWAY_SETTINGS,
  SET_POINT_SETTINGS,
  SET_ADDON_POINT_SETTINGS
} from '../constants';

/**
 * @description Getting gateway settings from API
 * @return {Promise} Axios promise
 */
export function getGatewaySettings() {
  let url = API_URL + '/settings/gateway';
  return dispatch => {
    return axios.get(url).then(res => {
      dispatch({
        type: SET_GATEWAY_SETTINGS,
        payload: res.data,
      });

      return res;
    });
  };
}

/**
 * @description Update gateway settings
 * @return {Promise} Axios promise
 */
export function updateGatewaySetting(setting) {
  let url = API_URL + '/settings/gateway';
  return dispatch => {
    return axios.post(url, setting).then(res => {
      dispatch({
        type: SET_GATEWAY_SETTINGS,
        payload: res.data,
      });

      return res;
    });
  };
}

/**
 * @description Get points settings from API
 * @return {Promise} Axios promise
 */
export function getPointSettings() {
  let url = API_URL + '/settings/countryCodes';
  return dispatch => {
    return axios.get(url).then(res => {
      dispatch({
        type: SET_POINT_SETTINGS,
        payload: res.data,
      });

      return res;
    });
  };
}

/**
 * @description Update points settings
 * @return {Promise} Axios promise
 */
export function updatePointSetting(setting) {
  let url = API_URL + '/settings/countryCodes';
  return dispatch => {
    return axios.post(url, setting).then(res => {
      dispatch({
        type: SET_POINT_SETTINGS,
        payload: res.data,
      });

      return res;
    });
  };
}


/**
 * @description Get addon points
 * @return {Promise} Axios promise
 */
export function getAddonPointSettings(customerId = null) {
  let url = API_URL + '/settings/addons';
  if (typeof customerId === 'number') {
    url += `/${customerId}`;
  }
  return dispatch => {
    return axios.get(url).then(res => {
      dispatch({
        type: SET_ADDON_POINT_SETTINGS,
        payload: res.data,
      });

      return res;
    });
  };
}

/**
 * @description Update addon points
 * @return {Promise} Axios promise
 */
export function updateAddonPointSetting(setting) {
  let url = API_URL + '/settings/addons';
  return dispatch => {
    return axios.post(url, setting).then(res => {
      dispatch({
        type: SET_ADDON_POINT_SETTINGS,
        payload: res.data,
      });

      return res;
    });
  };
}

/**
 * @description Reset addon points
 * @return {Promise} Axios promise
 */
export function resetAddonPointSetting(customerId, type) {
  let url = API_URL + `/settings/addons/reset/${customerId}/${type}`;
  return dispatch => {
    return axios.post(url, null).then(res => {
      dispatch({
        type: SET_ADDON_POINT_SETTINGS,
        payload: res.data,
      });

      return res;
    });
  };
}


/**
 * @description Get price range settings from API
 * @return {Promise} Axios promise
 */
export function getPriceRanges(customerId = null) {
  let url = API_URL + '/settings/priceranges';
  if (typeof customerId === 'number') {
    url += `/${customerId}`;
  }
  return dispatch => {
    return axios.get(url);
  };
}

/**
 * @description Update price range settings
 * @return {Promise} Axios promise
 */
export function updatePriceRange(priceRange, customerId = null) {
  let url = API_URL + '/settings/pricerange';
  if (typeof customerId === 'number') {
    url += `/${customerId}`;
  }
  return dispatch => {
    return axios.post(url, priceRange);
  };
}

/**
 * @description Reset price range settings
 * @return {Promise} Axios promise
 */
export function resetCustomerPriceRange(customerId) {
  let url = API_URL + '/settings/pricerange/reset/' + customerId;
  return dispatch => {
    return axios.post(url, null);
  };
}

/**
 * @description Get invoicing period settings
 * @return {Promise} Axios promise
 */
export function getInvoicePeriods() {
  let url = API_URL + '/settings/invoicePeriods';
  return dispatch => {
    return axios.get(url);
  };
}


/**
 * @description Get global settings
 * @return {Promise} Axios promise
 */
export function getGlobalSettings() {
  let url = API_URL + '/settings/global';
  return dispatch => {
    return axios.get(url);
  };
}


/**
 * @description Update global settings
 * @return {Promise} Axios promise
 */
export function updateGlobalSettings(settings) {
  let url = API_URL + '/settings/global';
  return dispatch => {
    return axios.post(url, settings);
  };
}
/**
 * @module actions/documentation
 */

import {
  SET_ENDPOINT,
  SET_CODE_EXAMPLES,
  SET_RESPONSE_BODIES,
} from '../constants';

import InitialCodeBlock from '../../pages/Documentation/General/CodeExamples';

/**
 * @description Set the endpoint for the documentation to display
 * @param  {String} endpoint Name of the endpoint
 */
export function setEndpoint(endpoint) {
  return dispatch => {
    dispatch({
      type: SET_ENDPOINT,
      payload: endpoint,
    });
  };
}

/**
 * @description Set code examples
 * @param  {Object} examples object containing examples
 */
export function setCodeExamples(examples) {
  return dispatch => {
    dispatch({
      type: SET_CODE_EXAMPLES,
      payload: examples,
    });
  };
}

/**
 * @description Set responses
 * @param  {Object} responses object containing response bodies for success and failures
 */
export function setResponseBodies(resposnes) {
  return dispatch => {
    dispatch({
      type: SET_RESPONSE_BODIES,
      payload: resposnes,
    });
  };
}

/**
 * @description Reset documentations
 */
export function resetDocumentation() {
  return dispatch => {
    dispatch({
      type: SET_CODE_EXAMPLES,
      payload: InitialCodeBlock,
    });
    dispatch({
      type: SET_RESPONSE_BODIES,
      payload: {},
    });
  };
}

import React, { Component } from 'react';
import { connect } from 'react-redux';

// Utils
import withTranslation from '../hocs/withTranslation';

// Actions
import { register } from '../redux/actions/auth';

import Typography from '../components/Typography';
import { Link } from 'react-router-dom';
import Smspilot from '../components/Custom/Smspilot';

import CustomerModal from '../components/Custom/CustomerModal'

class Register extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      success: false,
      email: ''
    };
  }

  onSubmit = registerObj => {
    this.setState({ loading: true })
    return this.props
      .register({ ...registerObj, language: this.props.lang })
      .then(() => {
        this.setState({
          success: true,
          loading: false,
          email: registerObj.contactMail
        });
      })
      .finally(() => {
        this.setState({ loading: false })
      });
  }

  render() {
    return (
      <div className='login_wrapper'>
        <div className='col-7 max-width'>
          {this.state.success && (
            <React.Fragment>
              <Smspilot />

              <Typography type='h4' className='text-center'>
                {this.props.t(161).replace('[email]', this.state.email)}
              </Typography>
              <Typography type='h5' className='text-center'>
                {this.props.t(162)}
              </Typography>

              <div className='text-center mt-4'>
                <Link to='/login'>
                  <u>{this.props.t(8)}</u>
                </Link>
              </div>
            </React.Fragment>
          )}

          {!this.state.success && (
            <React.Fragment>
              <Smspilot />

              <Typography type='h5' className='text-center mb-4'>
                {this.props.t(151)}
              </Typography>

              <CustomerModal
                onSubmit={this.onSubmit}
                loading={this.state.loading}
                useReCaptcha
                isRegister
              />

              <small className='d-block text-center mt-4'>
                {this.props.t(153)}
                &nbsp;
                <Link to='/login'>
                  <u>{this.props.t(154)}</u>
                </Link>
              </small>
            </React.Fragment>
          )}
        </div>
        <div className='footer'>
          <div className='items'>
            <small className='link-item'>
              {`smspilot ${this.props.t(193).toLowerCase()} `}
              <a href='https://itpilot.dk'>itpilot.dk</a>
            </small>
          </div>
        </div>
      </div>
    );
  }
}

export default withTranslation(connect(null, {
  register,
})(Register));

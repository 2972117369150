import {
  SET_USER_LANGUAGE,
  SET_TRANSLATIONS,
  SET_COUNTRY_CODES,
} from '../constants';
import { registerLocale, setDefaultLocale } from 'react-datepicker';
import { enUS, da } from 'date-fns/locale';
registerLocale('en', enUS);
registerLocale('da', da);

const initialState = {
  user_lang: 'dk',
  locale: 'da',
  translations: {},
  countryCodes: [],
};

export function enumToUserLang(value) {
  if (isNaN(value)) return value;

  switch (value) {
    case 2:
      return 'gb';
    case 1:
    default:
      return 'dk';
  }
}

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_USER_LANGUAGE: {
      if (action.user_lang === null || action.user_lang.match(/^ *$/) !== null)
        return state;

      action.user_lang = enumToUserLang(action.user_lang);
      switch (action.user_lang) {
        case 'dk':
          state.locale = 'da';
          break;
        case 'gb':
          state.locale = 'en';
          break;
        default:
          break;
      }
      setDefaultLocale(state.locale);

      return {
        ...state,
        user_lang: action.user_lang,
      };
    }
    case SET_TRANSLATIONS:
      return {
        ...state,
        translations: action.payload,
      };
    case SET_COUNTRY_CODES:
      return {
        ...state,
        countryCodes: action.countryCodes,
      };
    default:
      return state;
  }
};

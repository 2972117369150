import React, { Component } from 'react';
import { Card, CardBody } from 'reactstrap';
import { connect } from 'react-redux';
import withTranslation from '../../../hocs/withTranslation';

// Actions
import {
  setCodeExamples,
  setResponseBodies,
} from '../../../redux/actions/documentation';

// Utils
import Examples from './CodeExamples';

// Components
import Typography from '../../../components/Typography';
import CodeBlock from '../../../components/Custom/CodeBlock';
import MessageSplitting from './MessageSplitting';

class General extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: false,
    };
  }

  componentDidUpdate() {
    const { active } = this.state;
    if (this.props.doc.endpoint === 'General' && !active) {
      this.setState({ active: true }, () => {
        this.props.setCodeExamples(Examples);
        this.props.setResponseBodies({});
      });
    } else if (this.props.doc.endpoint !== 'General' && active) {
      this.setState({ active: false });
    }
  }

  renderIntroduction = () => {
    const { customerToken } = this.props;

    return (
      <div>
        <Typography type='h5'>{this.props.t(382)}</Typography>
        <Typography>{this.props.t(383)}</Typography>
        <Typography>
          {this.props.t(384)}: {process.env.REACT_APP_GATEWAY}
        </Typography>
        <Typography>
          {this.props.t(385)}: {customerToken}
        </Typography>
      </div>
    );
  };

  renderPhoneNumberSection = () => {
    return (
      <div>
        <Typography type='h5'>{this.props.t(365)}</Typography>
        <Typography>{this.props.t(360)}</Typography>
        <ul>
          <li>{this.props.t(373)}</li>
          <li>{this.props.t(374)}</li>
          <li>{this.props.t(375)}</li>
        </ul>
        <Typography>{this.props.t(376)}</Typography>
      </div>
    );
  };

  renderAuthenticationSection = () => {
    const { customerToken } = this.props;

    let settingsText = this.props.t(387).split('[SETTINGS_URL]');
    settingsText = (
      <div>
        <Typography>
          {settingsText[0]}
          <a className='link' href={'/settings'}>
            {this.props.t(389)}
          </a>
          {settingsText[1]}
        </Typography>
      </div>
    );

    return (
      <div>
        <Typography type='h5'>{this.props.t(366)}</Typography>
        <Typography>{this.props.t(361)}</Typography>
        <Typography>
          {this.props
            .t(386)
            .replace('[GATEWAY]', process.env.REACT_APP_GATEWAY)}
        </Typography>
        {settingsText}
        <Typography>{this.props.t(388)}:</Typography>
        <CodeBlock
          light
          language='makefile'
          code={`Authorization: Basic ${customerToken}`}
        />
      </div>
    );
  };

  renderGsmSection = () => {
    return (
      <div>
        <Typography type='h5'>{this.props.t(367)}</Typography>
        <Typography>{this.props.t(362)}</Typography>
        <ul>
          <li>{this.props.t(377)}</li>
          <li>{this.props.t(378)}</li>
          <li>{this.props.t(379)}</li>
          <li>{this.props.t(380)}</li>
          <li>{this.props.t(381)}</li>
        </ul>
        <Typography>
          {this.props.t(372)}
          {': '}
          <a className='link' href='https://en.wikipedia.org/wiki/GSM_03.38'>
            https://en.wikipedia.org/wiki/GSM_03.38
          </a>
        </Typography>
      </div>
    );
  };

  render() {
    return (
      <Card className='card-api-topic'>
        <CardBody>
          {this.renderIntroduction()}
          {this.renderAuthenticationSection()}
          {this.renderPhoneNumberSection()}
          {this.renderGsmSection()}
          {<MessageSplitting />}
        </CardBody>
      </Card>
    );
  }
}

function mapStateToProps({ doc }) {
  return {
    doc,
  };
}

export default withTranslation(
  connect(mapStateToProps, { setCodeExamples, setResponseBodies })(General),
);

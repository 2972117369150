import moment from 'moment';
import { parse } from 'date-fns';
import { hasLetter, hasNumber, hasMin8Chars } from './passwordValidator';

function reverseDate(date) {
  let arr = date.split('-');
  return `${arr[2]}-${arr[1]}-${arr[0]}`;
}

export const parseDate = (date, showTime = false) => {
  if (!showTime) {
    return moment(date).format('DD-MM-YYYY');
  } else {
    return `${moment(date).format('DD-MM-YYYY')}, kl. ${moment(date).format(
      'H:mm',
    )}`;
  }
};

export const parseDateNET = date => {
  return moment(date).format('MM-DD-YYYY');
};

export const parseDateRFC2822 = date => {
  const normal = parseDate(date);
  return parse(normal, 'dd-MM-yyyy', new Date());
};

export const dateIsBetween = (date, start, end) => {
  if (start === 'Invalid date' || end === 'Invalid date') return true;
  return moment(reverseDate(date)).isBetween(
    reverseDate(start),
    reverseDate(end),
    null,
    '[]',
  );
};

export const splitText = (text, firstPart, secondPart) => {
  if (text.length >= 17) {
    return `${firstPart}-\n${secondPart}`;
  }
  return text;
};

export const StringIsEmpty = str => {
  return !str || 0 === str.length;
};

export const ValidPhone = str => {
  let valid = false;

  if (!StringIsEmpty(str)) {
    if (!hasLetter.test(str)) {
      if (hasNumber.test(str)) {
        valid = hasMin8Chars.test(str);
      }
    }
  }

  return valid;
};

/**
 * Extract key-value pairs from query string.
 * @param {String} queryStr '?key=value&key=value'
 */
export const getQueryKeyValuePairs = queryStr => {
  const query = queryStr.replace('?', ''); // strip question mark

  return query.split('&').reduce((pairs, pair) => {
    const p = pair.split('=');

    pairs[p[0]] = p[1];
    return pairs;
  }, {});
};

/**
 * To be used in Array.sort for strings
 * @param {String} a
 * @param {String} b
 */
export const sortStrings = (a, b) => {
  const an = a.toUpperCase();
  const bn = b.toUpperCase();

  if (an === bn) {
    return 0;
  }
  return an < bn ? -1 : 1;
};

// utility functions
const default_cmp = function (a, b) {
  if (a === b) return 0;
  return a < b ? -1 : 1;
};

const getCmpFunc = function (primer, desc) {
  const dfc = default_cmp; // closer in scope
  let cmp = default_cmp;

  if (primer) {
    cmp = function (a, b) {
      return dfc(primer(a), primer(b));
    };
  }

  if (desc) {
    return function (a, b) {
      return -1 * cmp(a, b);
    };
  }

  return cmp;
};

/**
 * Sorts by multiple fields.
 * @param {Object} a { name, primer, desc }
 */
export const sortBy = function () {
  const fields = [];
  const n_fields = arguments.length;
  let field;
  let name;
  let cmp;

  // preprocess sorting options
  for (var i = 0; i < n_fields; i++) {
    field = arguments[i];
    if (typeof field === 'string') {
      name = field;
      cmp = default_cmp;
    } else {
      name = field.name;
      cmp = getCmpFunc(field.primer, field.desc);
    }

    fields.push({
      name: name,
      cmp: cmp,
    });
  }

  // final comparison function
  return function (A, B) {
    let name;
    let result;
    for (var i = 0; i < n_fields; i++) {
      result = 0;
      field = fields[i];
      name = field.name;

      result = field.cmp(A[name], B[name]);
      if (result !== 0) break;
    }

    return result;
  };
};

export const getCurrentLang = () => {
  let lang = localStorage.getItem('user_lang');

  if (!lang) {
    lang = 'DK';
  } else if (lang.length === 0) {
    lang = 'DK';
  }

  return lang.toUpperCase();
};

/**
 * Formats numbers like so: 1000 | 10,000 | 1,000,000
 * @param {Number} num Number
 */
export const numberWithCommas = x => {
  if (x === null || x === undefined) return '';
  if (!x && x !== 0) return '';
  let val = parseInt(x);
  if (isNaN(val)) return '';

  return x
    .toString()
    .replace(',', '.')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

/**
 * Formats numbers like so: 1000.00 | 10000.00 | 1000000.00
 * @param {Number} num integer
 */
export const getDecimal = (x, decimals = 1) => {
  if (decimals < 0) decimals = 0;
  if (decimals > 20) decimals = 20;

  return Number.parseFloat(x).toFixed(decimals);
};

/**
 * Formats numbers like so: 1,000.00 | 10,000.00 | 1,000,000.00
 * @param {Number} num integer
 */
export const getNumberDecimal = (x, decimals = 2) => {
  return numberWithCommas(getDecimal(x, decimals));
};

/**
 * This method will return a query match result based on the pixels value.
 * @param {Number} pixels integer
 */
export const queryMediaSize = pixels => {
  const isSmall = window.matchMedia(`(max-width: ${pixels}px)`);

  return isSmall;
};

/**
 * This method will return a boolean, checking if the text contains unicode chars (chars with a code > 127).
 * @param {String} str text value
 */
export const isUnicode = str => {
  if (!str) return false;
  if (str === 'null' || str === 'undefined') return false;

  const gsm_set = [
    '@',
    '£',
    '$',
    '¥',
    'è',
    'é',
    'ù',
    'ì',
    'ò',
    'Ç',
    '\n',
    'Ø',
    'ø',
    '\r',
    'Å',
    'å',
    'Δ',
    '_',
    'Φ',
    'Γ',
    'Λ',
    'Ω',
    'Π',
    'Ψ',
    'Σ',
    'Θ',
    'Ξ',
    '\x1b',
    'Æ',
    'æ',
    'ß',
    'É',
    ' ',
    '!',
    '"',
    '#',
    '¤',
    '%',
    '&',
    "'",
    '(',
    ')',
    '*',
    '+',
    ',',
    '-',
    '.',
    '/',
    '0',
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
    '9',
    ':',
    ';',
    '<',
    '=',
    '>',
    '?',
    '¡',
    'A',
    'B',
    'C',
    'D',
    'E',
    'F',
    'G',
    'H',
    'I',
    'J',
    'K',
    'L',
    'M',
    'N',
    'O',
    'P',
    'Q',
    'R',
    'S',
    'T',
    'U',
    'V',
    'W',
    'X',
    'Y',
    'Z',
    'Ä',
    'Ö',
    'Ñ',
    'Ü',
    '§',
    '¿',
    'a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z',
    'ä',
    'ö',
    'ñ',
    'ü',
    'à',
  ];

  for (let i = 0; i < str.length; i++) {
    if (!gsm_set.includes(str[i])) {
      return true;
    }
  }

  return false;
};

/**
 * This method will return a string with leading 0 infront of the number.
 * eg. zeroPad(2, 3) => '002'
 * @param {String} str text value
 */
export const zeroPad = (num, places = 2) => String(num).padStart(places, '0')
import React, { Component, useState, useEffect, useImperativeHandle } from 'react';
import { connect } from 'react-redux';
import { Table, Input, Button, Spinner } from 'reactstrap';

import {
  getAddonPointSettings,
  updateAddonPointSetting,
  resetAddonPointSetting,
} from '../redux/actions/settings';
import withTranslation from '../hocs/withTranslation';
import { getAddonPointSettingTranslationKey } from '../utils/translation';

import PageSpinner from '../components/PageSpinner';
import toast from '../utils/toast';

class PointAddonItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      updating: false,
      customerId: props.customerId,
      setting: props.setting,
      errors: {},
    };
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.customerId !== this.state.customerId ||
      Object.entries(nextProps.setting).toString() !==
      Object.entries(this.props.setting).toString()
    ) {
      this.setState({
        customerId: nextProps.customerId,
        setting: nextProps.setting,
      });
    } else return null;
  }

  onUpdate = () => {
    let dataObj = {
      ...this.state.setting,
      customerId: this.state.customerId,
    };

    this.setState({ updating: true });

    return this.props
      .updateAddonPointSetting(dataObj)
      .then(() => {
        if (!this.props.hideSave)
          toast.success(this.props.t(329));

        this.setState({ updating: false, errors: {} });
        return true // Success
      })
      .catch(err => {
        let errors = {};
        if (err.response) {
          if (err.response.data) {
            errors[`${err.response.data.value}`] = this.props.t(
              err.response.data.dictionaryKey,
            );
          }
        }
        this.setState({
          updating: false,
          errors,
        });

        return false // Error
      });
  };

  render() {
    let { updating, setting, customerId, errors } = this.state;
    let { hideSave, t } = this.props

    const onReset = () => {
      this.props.resetAddonPointSetting(customerId, setting.addonType);
    };

    const onChange = e => {
      this.setState({
        setting: {
          ...setting,
          points: e.target.value.replace(',', '.'),
        },
      });
    };

    let err;
    if (errors[setting.addonType]) {
      err = errors[setting.addonType];
    }

    return (
      <tr>
        <td colSpan={2}>
          {t(getAddonPointSettingTranslationKey(setting.addonType))}
        </td>
        <td>
          <Input
            name='addon'
            type='number'
            step='any'
            value={setting.points}
            onChange={onChange}
            bsSize='sm'
            style={{ textAlign: 'right' }}
          />
          {err && <div style={{ color: 'red', textAlign: 'right' }}>{err}</div>}
          {setting.isCustom && (
            <div style={{ textAlign: 'right' }}>{t(356)}</div>
          )}
        </td>
        <td>
          <div className='d-flex justify-content-around'>
            {setting.customerId != null && (
              <Button color='orange' className='mr-4' onClick={onReset}>
                {t(279)}
              </Button>
            )}
            {!hideSave && (
              <Button color='orange' className='' onClick={this.onUpdate}>
                {!updating && t(19)}
                {updating && <Spinner />}
              </Button>
            )}
          </div>
        </td>
      </tr>
    );
  }
}

const PointAddons = props => {
  const [loading, setLoading] = useState(true)

  let { t, addonPointSettings, customerId, striped, forwarded, getAddonPointSettings } = props
  let addonsRefs = []

  useEffect(_ => {
    getAddonPointSettings(customerId).then(() => {
      setLoading(false);
    });
  }, [])

  useImperativeHandle(forwarded, _ => ({
    update: _ => {
      return addonsRefs.map(ref => {
        return ref.onUpdate()
      })
    }
  }));

  return (
    <div className='settings gateway_tables'>
      <Table size='sm' striped={striped}>
        <thead>
          <tr>
            <th colSpan={2}>{t(147)}</th>
            <th>{t(148)}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {loading && (
            <tr>
              <td colSpan={4}>
                <PageSpinner />
              </td>
            </tr>
          )}
          {addonPointSettings.map((x, key) => (
            <PointAddonItem
              key={key}
              setting={x}
              customerId={customerId}
              ref={ref => addonsRefs[key] = ref}
              {...props}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    addonPointSettings: state.settings.addonPointSettings,
  };
}

export default withTranslation(
  connect(mapStateToProps, {
    getAddonPointSettings,
    updateAddonPointSetting,
    resetAddonPointSetting,
  })(PointAddons),
);

/**
 * @module actions/common
 */
import axios from 'axios';
import {
  API_URL,
  SET_USER_LANGUAGE,
  SET_COUNTRY_CODES,
  SET_TRANSLATIONS,
} from '../constants';
import EventEmitter from '../../utils/events';

/**
 * @description Change language
 * @return {Promise} Promise
 */
export function setLanguage(language, hasAuth = true) {
  let url = API_URL + '/settings/language/';
  return dispatch => {
    window.localStorage.setItem('user_lang', language);
    dispatch({
      type: SET_USER_LANGUAGE,
      user_lang: language,
    });
    EventEmitter.emit('USER_CHANGED_LANG');

    if (hasAuth)
      axios.post(url, { language });
  };
}

/**
 * @description Change language
 * @return {Promise} Promise
 */
export function getStatisticOptions() {
  let url = API_URL + `/sms/options`;
  return dispatch => {
    return axios.get(url);
  };
}

export function getDictionary() {
  let url = API_URL + '/settings/translations';
  return dispatch => {
    return axios.get(url).then(res => {
      dispatch({
        type: SET_TRANSLATIONS,
        payload: res.data,
      });
      EventEmitter.emit('USER_CHANGED_LANG');
      return res;
    });
  };
}

/**
 * Gets all supported country codes
 * @return {Promise} Axios promise
 */
export function getCountryCodes() {
  let url = API_URL + '/sms/countryCodes';
  return dispatch => {
    return axios.get(url).then(res => {
      dispatch({
        type: SET_COUNTRY_CODES,
        countryCodes: res.data,
      });

      return res;
    });
  };
}

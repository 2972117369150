import {
  SET_ENDPOINT,
  SET_CODE_EXAMPLES,
  SET_RESPONSE_BODIES,
} from '../constants';

import InitialExamples from '../../pages/Documentation/General/CodeExamples';

const initialState = {
  endpoint: 'General',
  examples: InitialExamples,
  responses: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_ENDPOINT:
      return {
        ...state,
        endpoint: action.payload,
      };
    case SET_CODE_EXAMPLES:
      return {
        ...state,
        examples: action.payload,
      };
    case SET_RESPONSE_BODIES:
      return {
        ...state,
        responses: action.payload,
      };
    default:
      return state;
  }
};

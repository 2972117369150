// Pages
import SendSms from './SendSms';
import SendSmsMulti from './SendSmsMulti';
import General from './General';

function createPageContent(title, component) {
  return {
    title,
    component,
  };
}

export default [
  createPageContent('General', General),
  createPageContent('SendSms', SendSms),
  createPageContent('SendSmsMulti', SendSmsMulti),
];

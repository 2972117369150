export default `private static void SendSMSByUrlRequest(string receiver, string txt)
{
    var endpoint = "[GATEWAY]";
    var token = "[API_TOKEN]";

    var client = new RestClient(endpoint + "/api/Sms/Send");
    var request = new RestRequest(Method.POST);

    request.AddHeader("Authorization", $"Basic {token}");

    var sms = new SmsRequest()
    {
        RecipientPhone = receiver,
        Message = txt,
        Sender = "" // Optional
    };

    request.AddJsonBody(sms);

    client.Execute(request);
}`;

import { SET_POINT_PACKAGES } from '../constants';

const initialState = {
  pointPackages: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_POINT_PACKAGES:
      return {
        ...state,
        pointPackages: action.payload
      };
    default:
      return state;
  }
}
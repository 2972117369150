import React from 'react';
import request from './request.js';
import StatusCodes from './StatusCodes';

export default [
  {
    title: 'Headers',
    language: 'json',
    code: request,
    component: <StatusCodes />,
  },
];

import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import axios from 'axios';

// Style
import 'react-toastify/dist/ReactToastify.css';
import './styles/reduction.scss';

import {
  setAuthorizationToken,
  setDeviceToken,
} from './utils/setAuthorizationToken';
import { SET_LOGIN_AS, SET_USER_LANGUAGE } from './redux/constants';
import { setCurrentUser } from './redux/actions/auth';
import configureStore, { history } from './redux/store';
import { logout } from './redux/actions/auth';
import { getDictionary } from './redux/actions/common';

import App from './App';

const initialState = {};
const store = configureStore(initialState);

// Pre-check of login-status - Tokens and user data
const usertoken = localStorage.getItem('user_token');
const userdata = localStorage.getItem('user_data');
const loggedInUser = localStorage.getItem('loggedInAs');
const user_lang = localStorage.getItem('user_lang');
if (usertoken && userdata) {
  if (loggedInUser) {
    const j = JSON.parse(loggedInUser);
    setAuthorizationToken(j.token);
    setDeviceToken(j.deviceToken);
    store.dispatch({
      type: SET_LOGIN_AS,
      user: loggedInUser,
    });
  } else {
    setAuthorizationToken(usertoken);
    setDeviceToken(JSON.parse(userdata).deviceToken);
  }

  store.dispatch(setCurrentUser(JSON.parse(userdata)));
}
store.dispatch({ type: SET_USER_LANGUAGE, user_lang })
store.dispatch(getDictionary());

// Axios response interceptor - Logout on 401
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response) {
      if (error.response.status === 401) {
        store.dispatch(logout());
        if (window.location.pathname !== '/login') {
          history.push('/');
          window.location.reload(true);
        }
      }
    }
    return Promise.reject(error);
  },
);

ReactDOM.render(
  <Provider store={store}>
    <App />
    <ToastContainer />
  </Provider>,
  document.getElementById('root'),
);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { MdKeyboardBackspace } from 'react-icons/md';
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CustomInput,
  Spinner,
  FormText,
  Container,
  NavLink,
  NavItem,
  TabContent,
  TabPane,
} from 'reactstrap';

// Actions
import {
  getCustomer,
  updateCustomer,
  getCustomerBillingInfo,
  updateCustomerBilling,
} from '../redux/actions/customers';

// Utils
import { getUserData } from '../utils/userUtil';
import withTranslation from '../hocs/withTranslation';

// Components
import Page from '../components/Page';
import PageSpinner from '../components/PageSpinner';
import Typography from '../components/Typography';
import Icon from '../components/Custom/Icon';
import Divider from '../components/Custom/Divider';
import TextInput from '../components/Custom/Forms/TextInput';
import BillingForm from '../components/Custom/BillingForm';

import CustomerPointsPage from './CustomerPointsPage';
import CustomerInvoicePage from './CustomerInvoicePage';

class CustomerEditPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      updating: false,
      error: false,
      customer: null,
      billingInfo: null,
      activeTab: '1',
      showTabs: false,
    };

    this.onChange = this.onChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentDidMount() {
    const user = getUserData(this.props.auth);

    let showTabs = false;
    let customerId = this.props.match.params.id;
    if (user.userType !== 1) {
      customerId = user.userInfo.id;
    } else {
      showTabs = true;
    }

    this.props.getCustomer(customerId).then(res1 => {
      this.props.getCustomerBillingInfo(customerId).then(res2 => {
        this.setState({
          loading: false,
          billingInfo: this.getBillingInfoFields(res2.data),
          customer: this.getCustomerFields(res1.data),
          showTabs,
        });
      });
    });
  }

  getCustomerFields(customer) {
    return {
      id: customer.id,
      active: customer.active,
      invoicingType: customer.invoicingType,
      invoicingPeriodInterval: customer.invoicingPeriodInterval,
      invoiceDueDate: customer.invoiceDueDate,
      senderId: customer.senderId,
      maxNumOfSmss: customer.maxNumOfSmss,
      notifyAtPoints: customer.notifyAtPoints,
      notifyViaEmail: customer.notifyViaEmail,
      notifyViaSms: customer.notifyViaSms,
      points: customer.points,
      hasCustomPriceRange: customer.hasCustomPriceRange,
      currencyType: customer.currencyType,
      sendInvoiceMail: customer.sendInvoiceMail,
      token: customer.token,
    };
  }

  getBillingInfoFields = info => {
    const customer = info.customer;
    const contact = info.contact;
    return {
      contactId: contact.id,
      contactName: contact.name,
      phone: contact.phone,
      countryCodeId: contact.countryCodeId,
      email: contact.mail,

      id: customer.id,
      companyName: customer.name,
      address: customer.address,
      zipCode: customer.zipCode,
      city: customer.city,
      cvrNumber: customer.cvr,
      ean: customer.ean,
      invoiceEmail: customer.invoiceEmail,

      errObj: {
        companyNameError: '',
        contactNameError: '',
        emailError: '',
        countryCodeIdError: '',
        phoneError: '',
        addressError: '',
        zipCodeError: '',
        cityError: '',
        cvrNumberError: '',
        invoiceEmailError: '',
      },
    };
  };

  onChange(e) {
    let { name, value, checked, type } = e.target;

    if (type === 'checkbox') {
      value = checked;
    }

    if (type === 'number') {
      if (value !== '' && value) {
        value = parseInt(value);
      }
    }

    this.setState({
      customer: {
        ...this.state.customer,
        [name]: value,
      },
    });
  }

  updateBillingInfo = () => {
    this.setState({ updating: true }, () => {
      this.props
        .updateCustomerBilling(this.state.billingInfo)
        .then(res => {
          this.setState(
            {
              updating: false,
              billingInfo: this.getBillingInfoFields(res.data),
            },
            () => this.props.history.goBack(),
          );
        })
        .catch(err => {
          this.handleBillingErrors(err);
          document.body.scrollTop = 0; // For Safari
          document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
        });
    });
  };

  onSubmit() {
    const { customer } = this.state;

    this.setState({ updating: true }, () => {
      this.props
        .updateCustomer(customer)
        .then(res => {
          this.setState(
            {
              updating: false,
              customer: this.getCustomerFields(res.data),
              error: false,
            },
            () => {
              this.updateBillingInfo();
            },
          );
        })
        .catch(() => {
          this.setState({
            updating: false,
            error: true,
          });
        });
    });
  }

  handleBillingErrors = errors => {
    if (errors) {
      if (errors.response) {
        if (Array.isArray(errors.response.data)) {
          const { data } = errors.response;
          let errObj = data.reduce((prev, errItem) => {
            let key = errItem.dictionaryKey;
            switch (key) {
              case 247:
                prev.companyNameError = this.props.t(key);
                break;
              case 281:
                prev.contactNameError = this.props.t(key);
                break;
              case 323:
              case 319:
              case 170:
                prev.emailError = this.props.t(key);
                break;
              case 245:
              case 246:
                prev.phoneError = this.props.t(key);
                break;
              case 241:
                prev.addressError = this.props.t(key);
                break;
              case 242:
                prev.zipCodeError = this.props.t(key);
                break;
              case 243:
                prev.cityError = this.props.t(key);
                break;
              case 244:
              case 517:
              case 520:
                prev.cvrNumberError = this.props.t(key);
                break;
              case 324:
              case 253:
                prev.invoiceEmailError = this.props.t(key);
                break;
              default:
                break;
            }

            return prev;
          }, {});

          this.setState({
            updating: false,
            billingInfo: {
              ...this.state.billingInfo,
              errObj,
            },
          });
        }
      }
    }
  };

  renderSettingInput(label, name, value, options) {
    let maxLength;
    let formText;
    let type;
    let bold;
    let inputText;

    if (options) {
      maxLength = options.maxLength;
      formText = options.formText;
      inputText = options.inputText;
      type = options.type;
      bold = options.bold;
    }

    let labelText = bold ? <strong>{label}</strong> : label;

    return (
      <FormGroup className='pt-4' row>
        <Col sm={8}>
          <Label for={name}>{labelText}</Label>
          {formText && <FormText>{formText}</FormText>}
        </Col>
        <Col sm={4}>
          <Input
            id={name}
            name={name}
            value={value || ''}
            onChange={this.onChange}
            maxLength={maxLength}
            type={type ? type : 'text'}
          />
          {inputText && <FormText>{inputText}</FormText>}
        </Col>
      </FormGroup>
    );
  }

  renderSettingSwitch(label, name, value, formText, bold = true) {
    return (
      <FormGroup className='pt-4' row>
        <Col sm={8}>
          <Label for={name}>
            {bold && <strong>{label}</strong>}
            {!bold && <span>{label}</span>}
          </Label>
          {formText && <FormText>{formText}</FormText>}
        </Col>
        <Col sm={4}>
          <CustomInput
            id={name}
            name={name}
            checked={value}
            onChange={this.onChange}
            type='switch'
            className='float-right'
          />
        </Col>
      </FormGroup>
    );
  }

  renderYourData = () => {
    const { billingInfo, updating } = this.state;

    const onBillingInfoChange = e => {
      let { name, value } = e.target;

      this.setState({
        billingInfo: {
          ...this.state.billingInfo,
          [name]: value,
        },
      });
    };

    return (
      <BillingForm
        billingInfo={billingInfo}
        onChange={onBillingInfoChange}
        isSettingsPage
        updating={updating}
      />
    );
  };

  renderCustomerInformation = () => {
    const { customer } = this.state;
    const { t } = this.props;
    const isCustomer = getUserData(this.props.auth).userType !== 1;

    let senderIdLength = 0;
    if (customer.senderId) {
      senderIdLength = customer.senderId.length;
    }

    return (
      <Card>
        <CardBody>
          <Form>
            <Typography type='h5'>
              <strong>{t(138)}</strong>
            </Typography>
            {this.renderYourData()}
            <Row form>
              <Col>
                <TextInput
                  for={this.props.t(337)}
                  value={customer.token}
                  disabled
                />
              </Col>
            </Row>
            <Typography className='mt-4' type='h5'>
              <strong>{t(94)}</strong>
            </Typography>
            {!isCustomer && (
              <React.Fragment>
                <FormGroup className='pt-4' row>
                  <Col sm={8}>
                    <Label for='active'>{t(30)}</Label>
                  </Col>
                  <Col sm={4}>
                    <CustomInput
                      type='switch'
                      id='active'
                      name='active'
                      checked={customer.active}
                      onChange={this.onChange}
                      className='float-right'
                    />
                  </Col>
                </FormGroup>
                <Divider />
              </React.Fragment>
            )}

            {this.renderSettingInput(
              t(142),
              'senderId',
              customer.senderId,
              {
                maxLength: 11,
                formText: t(282),
                bold: true,
                inputText: t(283).replace('[CHARS]', senderIdLength),
              },
            )}
            <Divider />

            <FormGroup className='pt-4' row>
              <Col sm={8}>
                <Label for='maxNumOfSmss'>{t(143)}</Label>
              </Col>
              <Col sm={4}>
                <Input
                  type='select'
                  id='maxNumOfSmss'
                  name='maxNumOfSmss'
                  value={customer.maxNumOfSmss}
                  onChange={this.onChange}
                >
                  <option value={1}>1</option>
                  <option value={2}>2</option>
                  <option value={3}>3</option>
                  <option value={4}>4</option>
                  <option value={5}>5</option>
                  <option value={6}>6</option>
                  <option value={7}>7</option>
                  <option value={8}>8</option>
                </Input>
              </Col>
            </FormGroup>
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderSMSPointNotification = () => {
    const { customer, billingInfo } = this.state;
    const { t } = this.props;
    return (
      <Card>
        <CardBody>
          <Form>
            <Typography type='h5'>
              <strong>{t(226)}</strong>
            </Typography>
            <FormText>{t(227)}</FormText>

            <FormGroup className='pt-4' row>
              <Col sm={8}>
                <Label for='notifyAtPoints'>
                  <strong>{t(228)}</strong>
                </Label>
                <FormText>{t(229)}</FormText>
              </Col>
              <Col sm={4}>
                <Input
                  type='select'
                  id='notifyAtPoints'
                  name='notifyAtPoints'
                  value={customer.notifyAtPoints}
                  onChange={this.onChange}
                >
                  <option value={100}>100</option>
                  <option value={500}>500</option>
                  <option value={1000}>1000</option>
                  <option value={5000}>5000</option>
                  <option value={10000}>10000</option>
                </Input>
              </Col>
            </FormGroup>

            <Divider />
            {this.renderSettingSwitch(
              t(230),
              'notifyViaEmail',
              customer.notifyViaEmail,
              t(231).replace('[RECIPIENT]', billingInfo.email),
            )}
            <Divider />
            {this.renderSettingSwitch(
              t(232),
              'notifyViaSms',
              customer.notifyViaSms,
              t(233).replace('[RECIPIENT]', billingInfo.phone),
            )}
          </Form>
        </CardBody>
      </Card>
    );
  };

  renderContent = () => {
    return (
      <div className='wrapper'>
        <Row>
          <Col>{this.renderCustomerInformation()}</Col>
        </Row>
        <Row>
          <Col>
            {`${this.state.customer.invoicingType}` !== '2' &&
              this.renderSMSPointNotification()}
          </Col>
        </Row>

        <Button
          color='orange'
          className='mt-4 mx-auto d-block'
          onClick={this.onSubmit}
        >
          {!this.state.updating && this.props.t(19)}
          {this.state.updating && <Spinner />}
        </Button>
        {this.state.error && <span style={{ color: 'red' }}>{this.props.t(345)}</span>}
      </div>
    );
  };

  renderTabContent = rootContent => {
    const { activeTab } = this.state;
    let settingsClass = 'settings gateway_tables';

    return (
      <Container fluid>
        <Row>
          <Col>
            <div className={settingsClass}>
              <TabContent activeTab={activeTab}>
                <TabPane tabId='1'>
                  {rootContent}
                </TabPane>
                <TabPane tabId='2'>
                  <CustomerInvoicePage {...this.props} />
                </TabPane>
                <TabPane tabId='3'>
                  <CustomerPointsPage {...this.props} />
                </TabPane>
              </TabContent>
            </div>
          </Col>
        </Row>
      </Container>
    );
  };

  renderSettingsNavigation = () => {
    const { loading, activeTab } = this.state;
    if (loading) return null;

    const buildNav = (tabId, textId) => {
      let classNames = 'settings__link can-click';

      if (activeTab === tabId) {
        classNames += ' active';
      }

      return (
        <NavItem className={classNames}>
          <NavLink
            active={activeTab === tabId}
            onClick={() => this.setState({ activeTab: tabId })}
          >
            {this.props.t(textId)}
          </NavLink>
        </NavItem>
      );
    };

    return (
      <div>
        {buildNav('1', 268)}
        {buildNav('2', 518)}
        {buildNav('3', 192)}
      </div>
    );
  };

  render() {
    const { customer, auth } = this.props;
    const isCustomer = getUserData(auth).userType !== 1;

    const title = name => {
      return (
        <Typography type='h2' className='customerEdit__title'>
          <Icon onClick={() => this.props.history.goBack()}>
            <MdKeyboardBackspace />
          </Icon>
          {name || this.props.t(4)}
        </Typography>
      );
    };

    let titleContent;
    let content = (
      <div className='customerEdit'>
        {this.state.loading && <PageSpinner />}
        {!this.state.loading && this.renderContent()}
      </div>
    );

    if (!isCustomer) {
      titleContent = title(customer ? customer.name : '');
      return (
        <Page
          title={titleContent}
          headerRight={this.renderSettingsNavigation()}
        >
          {this.renderTabContent(content)}
        </Page>
      );
    }

    return content;
  }
}

function mapStateToProps(state) {
  return {
    auth: state.auth,
    customer: state.customers.customer,
  };
}

export default withTranslation(
  connect(mapStateToProps, {
    getCustomer,
    updateCustomer,
    getCustomerBillingInfo,
    updateCustomerBilling,
  })(CustomerEditPage),
);

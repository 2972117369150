/**
 * @module actions/customers
 */
import axios from 'axios';
import { API_URL, SET_CUSTOMERS, SET_CUSTOMER } from '../constants';

/**
 * @description Getting customers from API and dispatching event
 * @return {Promise} Axios promise
 */
export function getCustomers() {
  let url = API_URL + `/customers`;
  return dispatch => {
    return axios.get(url).then(res => {
      if (res.status === 200) {
        dispatch({
          type: SET_CUSTOMERS,
          customers: res.data,
        });
      }
      return res;
    });
  };
}

/**
 * @description Getting a single customer from API and dispatching event
 * @return {Promise} Axios promise
 */
export function getCustomer(id) {
  let url = API_URL + `/customers/${id}`;
  return dispatch => {
    return axios.get(url).then(res => {
      if (res.status === 200) {
        dispatch({
          type: SET_CUSTOMER,
          customer: res.data,
        });
      }
      return res;
    });
  };
}

/**
 * @description Creating a customer
 * @return {Promise} Axios promise
 */
export function createCustomer(data) {
  let url = API_URL + `/customers/create`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Updating a customer
 * @return {Promise} Axios promise
 */
export function updateCustomer(data) {
  let url = API_URL + `/customers/update`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Updating a customers points
 * @return {Promise} Axios promise
 */
export function updateCustomerPoints(data) {
  let url = API_URL + `/customers/update/points`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description "Deleting" a customer
 * @return {Promise} Axios promise
 */
export function deleteCustomer(data) {
  let url = API_URL + `/customers/delete`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Get billing informaiton
 * @return {Promise} Axios promise
 */
export function getCustomerBillingInfo(id) {
  let url = API_URL + `/customers/${id}/billing`;
  return dispatch => {
    return axios.get(url);
  };
}

/**
 * @description Updating a customers billing information
 * @return {Promise} Axios promise
 */
export function updateCustomerBilling(data) {
  let url = API_URL + `/customers/update/billing`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Get customer API token
 * @return {Promise} Axios promise
 */
export function getCustomerToken() {
  let url = API_URL + `/customers/token`;
  return dispatch => {
    return axios.get(url);
  };
}

/**
 * @description Get customer Subscription
 * @return {Promise} Axios promise
 */
export function getSubscription() {
  let url = API_URL + `/customers/subscription`;
  return dispatch => {
    return axios.get(url);
  };
}

/**
 * @description Update customer Subscription
 * @return {Promise} Axios promise
 */
export function updateSubscription(data) {
  let url = API_URL + `/customers/update/subscription`;
  return dispatch => {
    return axios.post(url, data);
  };
}

/**
 * @description Get customer Invoice settings
 * @return {Promise} Axios promise
 */
export function getCustomerInvoiceSettings(id) {
  let url = API_URL + `/customers/invoice/${id}`;
  return dispatch => {
    return axios.get(url);
  };
}

/**
 * @description Update customer invoice settings
 * @return {Promise} Axios promise
 */
export function updateInvoiceSettings(data) {
  let url = API_URL + `/customers/update/invoice`;
  return dispatch => {
    return axios.post(url, data);
  };
}